import React, { useEffect, useState } from "react";
import { Formik, Form, Field, getIn } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getDrawingByOpportunityForUI } from "../../store/reducer/drawing/drawingSlice";
import { getMaterialListByDrawing } from "../../store/reducer/drawing/drawingSlice";
import downloadReport from "./utils/downloadReport";

function MaterialListReport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [drawings, setDrawings] = useState([]);

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  // Validation schema for the form
  const validationSchema = Yup.object().shape({
    opportunity: Yup.number().required("Please enter opportunity id"),
    drawing: Yup.number().required("Please select drawing"),
  });

  const initialValues = {
    opportunity: "",
    drawing: "",
  };

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      const result = await dispatch(getMaterialListByDrawing(values.drawing)).unwrap();
      const reportTitle = "Material List";
      if (result) {
        if (result.length === 0) {
          toast.warning("No records found.");
        } else {
          await downloadReport(result, 1, reportTitle);
          toast.success("Report generated successfully.");
          resetForm();
        }
      } else {
        toast.error("Failed to generate report.");
      }
    } catch (e) {
      console.error(e);
      toast.error("An error occurred while generating the report.");
    } finally {
      setSubmitting(false);
    }
  };

  // Handle opportunity selection change
  const handleOpportunityChange = (opportunityId, setFieldValue) => {
    setFieldValue("opportunity", opportunityId);
    setFieldValue("drawing", ""); // Reset drawing selection when opportunity changes
    setDrawings([]); // Clear previous drawings
    if (opportunityId) {
      dispatch(getDrawingByOpportunityForUI(opportunityId)).then((result) => {
        if (result.payload) {
          setDrawings(result.payload);
        }
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="p-[16px] rounded-[12px] bg-[#F8F8F8] items-center mb-[30px]">
            <h1 className="mb-[30px] text-[20px]">Material List</h1>
            <div className="grid grid-cols-4 gap-[20px] mb-[20px] bg-[#FFF] rounded-lg">
              <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider">
                Opportunity
              </div>
              <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider">
                Drawing
              </div>
            </div>
            <div className="grid grid-cols-4 gap-[20px] mb-[20px]">
              {/* Opportunity Dropdown */}
              <div className="mb-2">
                <div className="relative">
                  <Field
                    type="text"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="opportunity"
                    name="opportunity"
                    onChange={(e) =>
                      handleOpportunityChange(e.target.value, setFieldValue)
                    }
                  />
                  
                  <ErrorMessage name="opportunity" />
                </div>
              </div>

              {/* Drawing Dropdown */}
              <div className="mb-2">
                <div className="relative">
                  <Field
                    as="select"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="drawing"
                    name="drawing"
                    disabled={!values.opportunity} // Disable if no opportunity is selected
                  >
                    <option value="">Select a drawing</option>
                    {drawings.map((drawing) => (
                      <option key={drawing.id} value={drawing.id}>
                        {drawing.name || `Drawing ${drawing.id}`}
                      </option>
                    ))}
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                  <ErrorMessage name="drawing" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
            <div>
              <button
                type="submit"
                name="submit"
                className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                  isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Generate"
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default MaterialListReport;