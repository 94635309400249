import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listOpportunities = createAsyncThunk(
  "listOpportunities",
  async ({ search = "", page = 1, limit = 10, is_deleted }) => {
    const request = await api(
      `api/admin/opportunities?search=${search}&page=${page}&limit=${limit}&is_deleted=${is_deleted}`,
      "",
      "get"
    );
    return request;
  }
);

export const searchOpportunities = createAsyncThunk(
  "searchOpportunities",
  async ({
    customer_id,
    sales_executive,
    progress_status,
    win_probability,
    expected_close_date,
    next_contact_date,
    city,
    page,
    limit,
  }) => {
    const request = await api(
      `api/admin/searchopportunities`,
      {
        customer_id,
        sales_executive,
        progress_status,
        win_probability,
        expected_close_date,
        next_contact_date,
        city,
        page,
        limit,
      },
      "post"
    );
    return request;
  }
);

export const getOpportunityById = createAsyncThunk(
  "getOpportunityById",
  async (data) => {
    const request = await api(`api/admin/opportunity/${data}`, "", "get");
    return request;
  }
);

export const getOpportunityForUI = createAsyncThunk(
  "getOpportunityForUI",
  async () => {
    const request = await api(`api/admin/opportunityforui/`, "", "get");
    return request;
  }
);

export const getOpportunityByCustomerId = createAsyncThunk(
  "getOpportunityByCustomerId",
  async (data) => {
    const request = await api(`api/admin/opportunitybycustomer`, data, "post");
    return request;
  }
);

export const addOpportunity = createAsyncThunk(
  "addOpportunity",
  async (data) => {
    const request = await api("api/admin/opportunity", data, "post");
    return request;
  }
);

export const updateOpportunity = createAsyncThunk(
  "updateOpportunity",
  async ({ data, id }) => {
    const request = await api(`api/admin/opportunity/${id}`, data, "post");
    return request;
  }
);

export const deleteOpportunity = createAsyncThunk(
  "deleteOpportunity",
  async (id) => {
    const request = await api(`api/admin/opportunity/${id}`, {}, "delete");
    return request;
  }
);

export const updateBulkOpportunity = createAsyncThunk(
  "updateBulkOpportunity",
  async (data) => {
    const request = await api(`api/admin/opportunities/edit`, data, "post");
    return request;
  }
);

const opportunitySlice = createSlice({
  name: "opportunity",
  initialState: {
    loading: false,
    opportunities: [],
    success: null,
    error: null,
    totalCount: 0,
    totalEstimatedValue: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listOpportunities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listOpportunities.fulfilled, (state, action) => {
        state.opportunities = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.totalEstimatedValue = action?.payload.estimated_value;
        state.loading = false;
        state.error = null;
      })
      .addCase(listOpportunities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchOpportunities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchOpportunities.fulfilled, (state, action) => {
        state.opportunities = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.totalEstimatedValue = action?.payload.estimated_value;
        state.type = action?.payload.type;
        state.loading = false;
        state.error = null;
      })
      .addCase(searchOpportunities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addOpportunity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addOpportunity.fulfilled, (state, action) => {
        state.loading = false;
        state.opportunities = action.payload;
        state.error = null;
      })
      .addCase(addOpportunity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateOpportunity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOpportunity.fulfilled, (state, action) => {
        state.loading = false;
        state.opportunities = state.opportunities.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateOpportunity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOpportunity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteOpportunity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteOpportunity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateBulkOpportunity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBulkOpportunity.fulfilled, (state, action) => {
        state.loading = false;
        state.opportunities = action?.payload?.opportunities;
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateBulkOpportunity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default opportunitySlice.reducer;
