import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listCommercialInvoices = createAsyncThunk(
  "listCommercialInvoices",
  async ({ search = "", page = 1, limit = 10, drawing_id }) => {
    const request = await api(
      `api/admin/commercialinvoice/list/${drawing_id}?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

// Delete commercial invoice
export const deleteCommercialInvoice = createAsyncThunk(
  'deleteCommercialInvoice',
  async (invoiceId) => {
    const request = await api(
      `api/admin/commercialinvoice/delete/${invoiceId}`,
      {},
      "delete"
    );
    return request;
  }
);

const drawingCommercialInvoiceSlice = createSlice({
  name: "drawingCommercialInvoice",
  initialState: {
    loading: false,
    commercialInvoices: [],
    totalCount: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listCommercialInvoices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listCommercialInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.commercialInvoices = action.payload.invoices || [];
        state.totalCount = action.payload.totalCount || 0;
      })
      .addCase(listCommercialInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to fetch invoices";
      })
      .addCase(deleteCommercialInvoice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCommercialInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.commercialInvoices = state.commercialInvoices.filter(
          invoice => invoice.id !== action.meta.arg
        );
        state.totalCount -= 1;
      })
      .addCase(deleteCommercialInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default drawingCommercialInvoiceSlice.reducer;