import React, {
  useEffect,
  useState,
  Suspense,
} from "react";
import { useDispatch } from "react-redux";
import { getInstallItems } from "../../store/reducer/drawing/drawingPurchaseOrderSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const SupplierItems = React.lazy(() => import("./InstallItemsForPO"));

function PurchaseOrdersInstall({ areaField, supplier, drawing_id, purchaseOrderObject, setPurchaseOrderObject }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [installItems, setInstallItems] = useState([]);
  
  const refreshInstallData = () => {
    if (!supplier?.supplier_id) return;
    setLoading(true);
    dispatch(getInstallItems({ drawing_id })).then(
      (result) => {
        if (result.payload) {
          let itemsData = result.payload;
          if (areaField) {
            itemsData = itemsData.filter((install) => install.area_id == areaField);
          }
          // Aggregate items across all areas
          const aggregatedItems = itemsData.reduce((acc, current) => {
            if (!current) return acc;
            const key = `${current.item_id}`;
        
            if (!acc[key]) {
              acc[key] = {
                ...current,
                qty: 0,
                total_cost: 0,
                groupedInstalls: [],
              };
            } else {
              acc[key].area_name = "Multiple"
            }
            
            // Ensure these are treated as numbers before summing
            acc[key].qty += Number(current.qty) || 0;
            acc[key].total_cost += Number(current.total_cost) || 0;
            
            acc[key].groupedInstalls?.push(current.id);
            return acc;
          }, {});
          
          // Convert to array after aggregation
          const aggregatedItemsArray = Object.values(aggregatedItems).map(item => ({
            ...item,
            qty: parseFloat(item.qty).toFixed(2),
            total_cost: parseFloat(item.total_cost).toFixed(2),
          }));
          
          setInstallItems(aggregatedItemsArray);          
        }
        setLoading(false);
      }
    );
  };  

  useEffect(() => {
    refreshInstallData()
  }, [supplier?.supplier_id, drawing_id, areaField])

  const handleItemAssign = (item, supplier, assign) => {
    setPurchaseOrderObject((prevState) => {
      const updatedInstalls = { ...prevState.installs };
      let updatedInstallAssignedItems = [...(prevState.assignedItems?.installs || [])];
  
      if (assign) {
        if (!updatedInstalls[supplier?.supplier_id]) {
          updatedInstalls[supplier?.supplier_id] = { supplier, items: [item] };
        } else if (
          !updatedInstalls[supplier?.supplier_id].items.some(
            (it) => it.id === item.id
          )
        ) {
          updatedInstalls[supplier?.supplier_id].items.push(item);
        }
        if (!updatedInstallAssignedItems.some((it) => it.item.id === item.id)) {
          updatedInstallAssignedItems.push({ item, supplier });
        }
      } else {
        if (updatedInstalls[supplier?.supplier_id]) {
          updatedInstalls[supplier?.supplier_id].items = updatedInstalls[supplier?.supplier_id].items.filter(
            (it) => it.id !== item.id
          );
  
          // If no items left for this supplier, remove the supplier entry
          if (updatedInstalls[supplier?.supplier_id].items.length === 0) {
            delete updatedInstalls[supplier?.supplier_id];
          }
        }
  
        updatedInstallAssignedItems = updatedInstallAssignedItems.filter(
          (it) => it.item.id !== item.id
        );
      }
  
      return {
        ...prevState,
        installs: updatedInstalls,
        assignedItems: { ...prevState.assignedItems, installs: updatedInstallAssignedItems.length > 0 ? updatedInstallAssignedItems : undefined },
      };
    });
  };

  return (
    <>
      {!loading ? (
        <div className="flex gap-[20px] mb-[10px]">
          <div className="bg-[#F8F8F8] w-full rounded-[12px] p-4 items-center">
            {supplier?.supplier_id ? (
              <Suspense fallback={<div>Loading Supplier Items...</div>}>
                <SupplierItems
                  handleItemAssign={handleItemAssign}
                  supplier={supplier}
                  purchaseOrderObject={purchaseOrderObject}
                  installItems={installItems}
                  refreshInstallData={refreshInstallData}
                  drawing_id={drawing_id}
                  areaField={areaField}
                />
              </Suspense>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
        </div>
      )}
    </>
  );
}

export default PurchaseOrdersInstall;
