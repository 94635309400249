import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlash, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createPurchaseOrder } from "../../store/reducer/drawing/drawingPurchaseOrderSlice";
import { CSVLink } from "react-csv";

function PurchaseOrderCart({ data, activeCategory, setPurchaseOrderObject }) {
  const { drawing_id, id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const activeTabCategoryMap = {
    1: "bom",
    2: "installs",
    3: "additionalOptions"
  }
  const handleCreatePurchaseOrder = async (e, values) => {
    e.target.textContent = "Creating.."
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      const value = values[fieldName];
      if (typeof value === 'object' && value !== null) {
        formData.append(fieldName, JSON.stringify(value));
      } else {
        formData.append(fieldName, value);
      }
    });

    await dispatch(createPurchaseOrder({ data: formData })).then((result) => {
      if (result.payload) {
        setPurchaseOrderObject((prevPO) => {
          const updatedPO = { ...prevPO };
          const category = activeTabCategoryMap[activeCategory];
          const supplierId = values.supplier_id;
          const itemIds = values.items.map(item => item.id);
          updatedPO[category][supplierId].items = updatedPO[category][supplierId]?.items?.filter(it => !itemIds.includes(it.id));
          if(updatedPO[category][supplierId].items.length == 0) delete updatedPO[category][supplierId]
          return updatedPO;
        });
    
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
        e.target.textContent = "Retry"
      }
    });
  };

  return (
    <>
      {!loading ? (
        <>
            <span className="font-Inter leading-[30px] text-[18px]">
              PO Cart
            </span>
            <div className="border border-gray-200 mt-2">
              <div className="bg-[#F8F8F8] p-2">
                <div className="flex flex-col">
                    <div className="mt-1 overflow-y-auto max-h-[250px] min-h-[250px]">
                        <table className="w-full">
                            <thead className="bg-white">
                            <tr className="rounded-[12px]">
                                <th
                                scope="col"
                                className="px-3 py-1 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                                >
                                Supplier
                                </th>
                                <th
                                scope="col"
                                className="px-3 py-1 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                                >
                                Items
                                </th>
                                <th
                                scope="col"
                                className="px-3 py-1 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                                >
                                Discount
                                </th>
                                <th
                                scope="col"
                                className="px-3 py-1 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                                >
                                Order
                                </th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {Object.keys(data[activeTabCategoryMap[activeCategory]]).length ? (
                                Object.entries(data[activeTabCategoryMap[activeCategory]])?.map(([supplier_id, value]) => (
                                  <tr key={`${supplier_id}-${activeCategory}`}>
                                    <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                                      {value.supplier?.supplier_name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <select
                                        className="block appearance-none w-full bg-gray-100 border border-gray-300 text-gray-700 p-2 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        key={`${supplier_id}-${activeCategory}`}
                                      >
                                        {value.items?.map((item, index) => {
                                          let label;
                                          let category = activeTabCategoryMap[activeCategory]
                                          if(category == "bom") label = `${item.item_description}, Total Quantity - ${item.revised_qty}${item.extra_details ? `, Extra Details - ${item.extra_details}` : ''}`
                                          else if(category == "installs") label = `${item.item_description}, Total Quantity - ${item.qty}`
                                          else if(category == "additionalOptions") label = `${item.item_description}, Cost - ${item.cost}${item.sq_m ? `, Sq M - ${item.sq_m}` : ''}`
                                          
                                          return (
                                          <option key={index} value={item.item_id} label={label} />
                                        )
                                        })}
                                      </select>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                                      <input 
                                        id={`discount-${supplier_id}-${activeCategory}`}
                                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                      />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                                      <div className="flex gap-2 w-full">
                                        <button
                                          type="submit"
                                          name="submit"
                                          className="py-[6px] px-[6px] text-center text-white flex-1 rounded-[6px] text-[13px] bg-[#1C1C20]"
                                          id={`createpo-${supplier_id}-${activeCategory}`}
                                          onClick={(e) => handleCreatePurchaseOrder(e, {
                                            supplier_id,
                                            drawing_id: data.drawing_id,
                                            area_id: data.area_id,
                                            opportunity_id: data.opportunity_id,
                                            category: activeTabCategoryMap[activeCategory],
                                            discount: document.getElementById(`discount-${supplier_id}-${activeCategory}`)?.value || 0,
                                            items: value.items,
                                            createdBy: JSON.parse(localStorage.getItem("userSession")).id
                                          })}
                                        >
                                          Create PO
                                        </button>
                                        {activeTabCategoryMap[activeCategory] === "bom" && (
                                          <CSVLink
                                            data={value.items.map(item => ({
                                              "Item Name": item.item_description,
                                              "Extra Details": item.extra_details || "",
                                              "Qty": item.qty,
                                              "Item Length": item.item_length,
                                              "UOM": item.UOM || "",
                                              "Total Qty": item.revised_qty
                                            }))}
                                            headers={[
                                              { label: "Item Name", key: "Item Name" },
                                              { label: "Extra Details", key: "Extra Details" },
                                              { label: "Qty", key: "Qty" },
                                              { label: "Item Length", key: "Item Length" },
                                              { label: "UOM", key: "UOM" },
                                              { label: "Total Qty", key: "Total Qty" }
                                            ]}
                                            filename={`materiallist_${id}_${drawing_id}.csv`}
                                            className="py-[6px] px-[6px] text-center text-white flex-1 rounded-[6px] text-[13px] bg-[#4CAF50] block"
                                            id={`materiallist-${supplier_id}-${activeCategory}`}
                                          >
                                            Material List
                                          </CSVLink>
                                        )}
                                      </div>
                                    </td>
                                  </tr>                                      
                                ))
                            ) : (
                                <tr>
                                    <td colspan="6">
                                        <h3 className="p-12 text-gray-400"> Add items </h3>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
            </div>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default PurchaseOrderCart;
