import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import {
  deleteInstallItem,
  generateInstall,
  getGenerateInstallStatus,
  getInstallByDrawingId,
  updateDrawingInstall,
} from "../../store/reducer/drawing/drawingInstallSlice";
import { getItemsForUi } from "../../store/reducer/items/itemSlice";
import { getDrawingById, getDrawingLockStatus } from "../../store/reducer/drawing/drawingSlice";
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import {  getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import { deletePandLOnChange } from "../../store/reducer/drawing/drawingPandLSlice";
import { deleteProposalOnChange } from "../../store/reducer/drawing/drawingFileSilce";
import { getSiteByDrawingId } from "../../store/reducer/drawing/drawingSiteSlice";
import Notes from "../Notes/Notes";

function EditDrawingInstall() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setdrawingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [installData, setInstallData] = useState([]);
  const [proposalStatus, setProposalStatus] = useState(true);
  const [generateInstallStatus, setGenerateInstallStatus] = useState(true);
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);
  const [displayInstall, setDisplayInstall] = useState(false)
  const [isDrawingLocked, setIsDrawingLocked] = useState(false);

  const { items = [] } = useSelector((state) => state.items);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  useEffect(() => {
    dispatch(getSiteByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        if(result.payload.install != 2) setDisplayInstall(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    
    dispatch(getDrawingLockStatus(drawing_id)).then(result => {
      if(result?.payload?.locked) {
        setIsDrawingLocked(result.payload.locked);
      }
    });
    dispatch(getProposalFinalStatus(drawing_id)).then(result => {
      if(result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    })
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });

    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setdrawingData(result.payload);

        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
      }
    });

    dispatch(getItemsForUi());

    dispatch(getGenerateInstallStatus(drawing_id)).then((result) => {
      if (result.payload.hasData) {
        setGenerateInstallStatus(true);
      } else {
        setGenerateInstallStatus(false);
      }
    });

    dispatch(getInstallByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setInstallData(result.payload);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const handleGenerateInstall = () => {
    setLoading(true);
    dispatch(generateInstall(drawing_id)).then((result) => {
      if (result.payload) {
        dispatch(proposalStatusByDrawingId(drawingData.drawing_uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(result.payload.hasData);
            }
          }
        );
        dispatch(getInstallByDrawingId(drawing_id)).then((result) => {
          if (result.payload) {
            setInstallData(result.payload);
            setLoading(false);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const validationSchema = Yup.object().shape({
    install_items: Yup.array()
      .of(
        Yup.object().shape({
          item_id: Yup.string().required("please select item"),
          qty: Yup.number().required("required").integer(),
        })
      )
      .required("Must have install"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.drawing_id = drawing_id;
    values.drawing_uniqueId = drawingData.uniqueId;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    const hasItemChanged = (originalItem, updatedItem) => {
      return Object.keys(updatedItem).some((key) => {
        // Skip the updatedAt field
        if (key === "updatedAt") return false;
    
        // Handle null/undefined safely
        const originalValue = originalItem[key] ?? "";
        const updatedValue = updatedItem[key] ?? "";
    
        // For objects/arrays, do deep comparison via JSON.stringify
        if (typeof originalValue === "object" || typeof updatedValue === "object") {
          return JSON.stringify(originalValue) !== JSON.stringify(updatedValue);
        }
    
        // Primitive type comparison (numbers, strings, booleans)
        return originalValue !== updatedValue;
      });
    };

    // Compare current values with the original installData
    const updatedInstallItems = values.install_items.map((item, index) => {
      const originalItem = installData[activeTab]?.install_items[index];

      // Set UPDATE_FLAG to 1 only if there is a change
      const isChanged = originalItem ? hasItemChanged(originalItem, item) : false;

      return {
        ...item,
        UPDATE_FLAG: isChanged ? 1 : item.UPDATE_FLAG || 0 // Keep existing flag if not changed
      };
    });

    // Update the values with the modified install_items
    values.install_items = updatedInstallItems;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      if (fieldName === "install_items") {
        formData.append(fieldName, JSON.stringify(values[fieldName]));
      } else {
        formData.append(fieldName, values[fieldName]);
      }
    });
    
    // Wait for the delete action to complete
    let deleteResult = await dispatch(deletePandLOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete pandl data.");
      setSubmitting(false);
      return;
    }
  
    deleteResult = await dispatch(deleteProposalOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete proposal data.");
      setSubmitting(false);
      return;
    }
    
    await dispatch(updateDrawingInstall({ data: formData, drawing_id })).then(
      (result) => {
        if (result.payload) {
          dispatch(getInstallByDrawingId(drawing_id)).then((result) => {
            if (result.payload) {
              setInstallData(result.payload);
            }
          });
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };

  const handleDeleteItem = async (id) => {
    // Wait for the delete action to complete
    let deleteResult = await dispatch(deletePandLOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete pandl data.");
      return;
    }
  
    deleteResult = await dispatch(deleteProposalOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete proposal data.");
      return;
    }
    
    dispatch(deleteInstallItem({ id })).then((result) => {
      if (result.payload) {
        dispatch(getGenerateInstallStatus(drawing_id)).then((result) => {
          if (result.payload.hasData) {
            setGenerateInstallStatus(true);
          } else {
            setGenerateInstallStatus(false);
          }
        });
        dispatch(getInstallByDrawingId(drawing_id)).then((result) => {
          if (result.payload) {
            setInstallData(result.payload);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="block md:grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Drawing Install / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Install
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-install" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Commercial Invoice
          </div>
        </Link>
      </div>
      {displayInstall ? !loading ? (
        <>
          <div className="border border-gray-200 p-4 rounded-lg">
            <div className="grid grid-cols-5 gap-[20px] justify-between mb-2 overflow-x-scroll">
              <div className="col-span-4">
                <div className="flex gap-[30px] text-nowrap p-2">
                  {installData.length ? (
                    installData.map((install, index) => {
                      return (
                        <span
                          className={`flex justify-center border-b-4 py-2 px-5 cursor-pointer ${
                            activeTab === index
                              ? "text-[#55A14A] border-b-4 border-[#55A14A]"
                              : "text-black border-transparent hover:border-b-4 hover:border-[#55A14A] hover:text-[#55A14A]"
                          }`}
                          key={index}
                          onClick={() => handleTabClick(index)}
                          data_id={index}
                        >
                          {install.area_name}
                        </span>
                      );
                    })
                  ) : (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  )}
                </div>
              </div>
              {!proposalStatus && !isProposalFinalised && !isDrawingLocked && (
                <div className="grid-cols-1">
                  <button onClick={handleGenerateInstall}>
                    <div className="py-[10px] px-[40px] font-[500] font-Inter text-[18px] text-center text-[#17171B] rounded-[12px] text-[#55A14A] border border-[#55A14A]">
                      <span>Generate Install</span>
                    </div>
                  </button>
                </div>
              )}
            </div>

            {installData.length ? (
              installData
                .filter((install, index) => index === activeTab)
                .map((install, index) => {
                  let initialValues = {
                    area_id: `${install.area_id}`,
                    area_uniqueId: `${install.area_uniqueId}`,
                    area_name: `${install.area_name}`,
                    install_items: install.install_items.length
                      ? install.install_items
                      : [
                          {
                            id: "",
                            uniqueId: "",
                            item_id: "",
                            qty: "",
                            labour_cost: "",
                            non_labour_cost: "",
                            total_labour_cost: "",
                            total_non_labour_cost: "",
                            total_cost: "",
                            labour_sell: "",
                            non_labour_sell: "",
                            total_labour_sell: "",
                            total_non_labour_sell: "",
                            total_sell: "",
                          },
                        ],
                  };
                  return (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                      key={install.area_id}
                    >
                      {({
                        isSubmitting,
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleSubmit,
                      }) => (
                        <Form>
                          <div>
                            <div className="sm:rounded-lg mt-5 overflow-scroll">
                              <FieldArray name="install_items">
                                {({ insert, remove, push }) => {
                                  const installItemsIdSet = new Set(
                                    values?.install_items?.map(
                                      ({ item_id }) => +item_id
                                    ) || []
                                  );

                                  return (
                                    <div>
                                      <div className="overflow-x-scroll">
                                        <div className="sm:rounded-lg mt-5 w-[350%] md:w-full">
                                          <div className="grid grid-cols-12 gap-[20px] bg-[#F8F8F8] ">
                                            <div className="col-span-2 px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                                              Item name
                                            </div>
                                            <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                              Quantity
                                            </div>
                                            <div className="col-span-8 flex gap-[20px] justify-around">
                                              <div>&nbsp;</div>
                                              <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                                Labour
                                              </div>
                                              <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                                Non Labour
                                              </div>
                                              <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                                Total Labour
                                              </div>
                                              <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                                Total Non Labour
                                              </div>
                                              <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                                Total
                                                <p>Cost: ({new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(
                                                  values?.install_items?.reduce((acc, it) => acc + (Number(it.total_cost) || 0), 0)
                                                )})</p>
                                                <p>Sell: ({new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(
                                                  values?.install_items?.reduce((acc, it) => acc + (Number(it.total_sell) || 0), 0)
                                                )})</p>
                                              </div>
                                            </div>

                                            <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                              Actions
                                            </div>
                                          </div>

                                          {values.install_items.length > 0 &&
                                            values.install_items.map(
                                              (bom, index) => {
                                                return (
                                                  <div
                                                    className="grid grid-cols-12 gap-[20px] mt-5 items-center"
                                                    key={index}
                                                  >
                                                    <div className="col-span-2">
                                                      <div className="relative">
                                                        <Field
                                                          as="select"
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                          id={`install_items.${index}.item_id`}
                                                          name={`install_items.${index}.item_id`}
                                                          onChange={(e) => {
                                                            let selectedItem =
                                                              e.target.value;
                                                            setFieldValue(
                                                              `install_items.${index}.item_id`,
                                                              selectedItem
                                                            );
                                                            let item_detail =
                                                              items.filter(
                                                                (item) =>
                                                                  item.id ==
                                                                  selectedItem
                                                              );
                                                            const labourCost =
                                                              parseFloat(
                                                                item_detail[0]
                                                                  .install_rate ||
                                                                  0
                                                              ).toFixed(2);
                                                            const nonLabourCost =
                                                              parseFloat(
                                                                item_detail[0]
                                                                  .install_accomodation_rate ||
                                                                  0
                                                              ).toFixed(2);
                                                            setFieldValue(
                                                              `install_items.${index}.labour_cost`,
                                                              labourCost
                                                            );
                                                            setFieldValue(
                                                              `install_items.${index}.non_labour_cost`,
                                                              nonLabourCost
                                                            );
                                                            const sellLabourCost =
                                                              parseFloat(
                                                                item_detail[0]
                                                                  .sell_labour_factor ||
                                                                  0
                                                              ).toFixed(2);
                                                            const sellNonLabourCost =
                                                              parseFloat(
                                                                item_detail[0]
                                                                  .sell_non_labour_factor ||
                                                                  0
                                                              ).toFixed(2);
                                                            setFieldValue(
                                                              `install_items.${index}.labour_sell`,
                                                              sellLabourCost
                                                            );
                                                            setFieldValue(
                                                              `install_items.${index}.non_labour_sell`,
                                                              sellNonLabourCost
                                                            );
                                                          }}
                                                        >
                                                          <option value={""}>
                                                            Select Item
                                                          </option>
                                                          {items
                                                            .filter(
                                                              (item) =>
                                                                item.group_id ==
                                                                  426 &&
                                                                (bom.item_id ||
                                                                  !installItemsIdSet.has(
                                                                    +item.id
                                                                  ))
                                                            )
                                                            .map((item) => (
                                                              <option
                                                                value={item.id}
                                                                key={item.id}
                                                              >
                                                                {
                                                                  item.item_description
                                                                }
                                                              </option>
                                                            ))}
                                                        </Field>
                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                          <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                          >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                          </svg>
                                                        </div>
                                                      </div>
                                                      <ErrorMessage
                                                        name={`install_items.${index}.item_id`}
                                                      />
                                                    </div>
                                                    <div>
                                                      <Field
                                                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                        id={`install_items.${index}.qty`}
                                                        name={`install_items.${index}.qty`}
                                                        onChange={(e) => {
                                                          const qty =
                                                            e.target.value;
                                                          setFieldValue(
                                                            `install_items.${index}.qty`,
                                                            qty
                                                          );
                                                          const labourCost =
                                                            parseFloat(
                                                              values
                                                                .install_items[
                                                                index
                                                              ].labour_cost || 0
                                                            );
                                                          const nonLabourCost =
                                                            parseFloat(
                                                              values
                                                                .install_items[
                                                                index
                                                              ]
                                                                .non_labour_cost ||
                                                                0
                                                            );

                                                          const labourSell =
                                                            parseFloat(
                                                              values
                                                                .install_items[
                                                                index
                                                              ].labour_sell || 0
                                                            );
                                                          const nonLabourSell =
                                                            parseFloat(
                                                              values
                                                                .install_items[
                                                                index
                                                              ]
                                                                .non_labour_sell ||
                                                                0
                                                            );

                                                          const totalLabourCost =
                                                            (
                                                              parseFloat(
                                                                qty || 0
                                                              ) * labourCost
                                                            ).toFixed(2);

                                                          const totalNonLabourCost =
                                                            (
                                                              parseFloat(
                                                                qty || 0
                                                              ) * nonLabourCost
                                                            ).toFixed(2);

                                                          const totalLabourSell =
                                                            (
                                                              parseFloat(
                                                                qty || 0
                                                              ) * labourSell
                                                            ).toFixed(2);

                                                          const totalNonLabourSell =
                                                            (
                                                              parseFloat(
                                                                qty || 0
                                                              ) * nonLabourSell
                                                            ).toFixed(2);

                                                          const totalCost =
                                                            parseFloat(
                                                              totalLabourCost
                                                            ) +
                                                            parseFloat(
                                                              totalNonLabourCost
                                                            );
                                                          const totalSell =
                                                            parseFloat(
                                                              totalLabourSell
                                                            ) +
                                                            parseFloat(
                                                              totalNonLabourSell
                                                            );

                                                          setFieldValue(
                                                            `install_items.${index}.total_labour_cost`,
                                                            totalLabourCost
                                                          );
                                                          setFieldValue(
                                                            `install_items.${index}.total_non_labour_cost`,
                                                            totalNonLabourCost
                                                          );
                                                          setFieldValue(
                                                            `install_items.${index}.total_labour_sell`,
                                                            totalLabourSell
                                                          );
                                                          setFieldValue(
                                                            `install_items.${index}.total_non_labour_sell`,
                                                            totalNonLabourSell
                                                          );
                                                          setFieldValue(
                                                            `install_items.${index}.total_cost`,
                                                            parseFloat(
                                                              totalCost
                                                            ).toFixed(2)
                                                          );
                                                          setFieldValue(
                                                            `install_items.${index}.total_sell`,
                                                            parseFloat(
                                                              totalSell
                                                            ).toFixed(2)
                                                          );
                                                        }}
                                                      ></Field>
                                                      <ErrorMessage
                                                        name={`install_items.${index}.qty`}
                                                      />
                                                    </div>
                                                    <div className="col-span-8 border rounded-[12px] border-[#F0F1F2] py-2 px-4">
                                                      <div className="flex gap-[20px] justify-between items-center">
                                                        <div className="w-[100px]">
                                                          Cost
                                                        </div>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.labour_cost`}
                                                          name={`install_items.${index}.labour_cost`}
                                                        ></Field>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.non_labour_cost`}
                                                          name={`install_items.${index}.non_labour_cost`}
                                                        ></Field>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.total_labour_cost`}
                                                          name={`install_items.${index}.total_labour_cost`}
                                                        ></Field>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.total_non_labour_cost`}
                                                          name={`install_items.${index}.total_non_labour_cost`}
                                                        ></Field>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.total_cost`}
                                                          name={`install_items.${index}.total_cost`}
                                                        ></Field>
                                                      </div>
                                                      <div className="flex gap-[20px] justify-between items-center mt-3">
                                                        <div className="mr-2">
                                                          Sell
                                                        </div>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.labour_sell`}
                                                          name={`install_items.${index}.labour_sell`}
                                                        ></Field>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.non_labour_sell`}
                                                          name={`install_items.${index}.non_labour_sell`}
                                                        ></Field>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.total_labour_sell`}
                                                          name={`install_items.${index}.total_labour_sell`}
                                                        ></Field>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.total_non_labour_sell`}
                                                          name={`install_items.${index}.total_non_labour_sell`}
                                                        ></Field>
                                                        <Field
                                                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                          id={`install_items.${index}.total_sell`}
                                                          name={`install_items.${index}.total_sell`}
                                                        ></Field>
                                                      </div>
                                                    </div>

                                                    {values.install_items
                                                      .length > 1 && !isProposalFinalised && !isDrawingLocked && (
                                                      <div className="py-2 text-center">
                                                        <span
                                                          className="text-red-400 hover:text-red-700 p-3 bg-white rounded-lg cursor-pointer"
                                                          onClick={() => {
                                                            if (
                                                              values
                                                                .install_items[
                                                                index
                                                              ].uniqueId
                                                            ) {
                                                              handleDeleteItem(
                                                                values
                                                                  .install_items[
                                                                  index
                                                                ].id
                                                              );
                                                            }
                                                            remove(index);
                                                          }}
                                                        >
                                                          <FontAwesomeIcon
                                                            icon={faTrash}
                                                            size="xl"
                                                          />
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                      <div className="text-right p-[16px]">
                                        {!isProposalFinalised && !isDrawingLocked && (
                                          <span
                                            className="cursor-pointer text-[#55A14A]"
                                            onClick={() =>
                                              push({
                                                id: "",
                                                uniqueId: "",
                                                item_id: "",
                                                qty: "",
                                                labour_cost: "",
                                                non_labour_cost: "",
                                                total_labour_cost: "",
                                                total_non_labour_cost: "",
                                                total_cost: "",
                                                labour_sell: "",
                                                non_labour_sell: "",
                                                total_labour_sell: "",
                                                total_non_labour_sell: "",
                                                total_sell: "",
                                              })
                                            }
                                          >
                                            + Add New Item
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }}
                              </FieldArray>

                              {!isProposalFinalised && !isDrawingLocked && (
                                <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                                  <div>
                                    <button
                                      type="submit"
                                      name="submit"
                                      className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                                        isSubmitting || proposalStatus
                                          ? "bg-gray-300"
                                          : "bg-[#1C1C20]"
                                      }`}
                                      disabled={isSubmitting || proposalStatus}
                                    >
                                      {isSubmitting ? (
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                      ) : (
                                        "Save"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  );
                })
            ) : (
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="2xl"
                  style={{ color: "#55A14A" }}
                  spin
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="text-center">
          <span>No data available</span>
        </div>
      ): (
      <div className="text-center">
        <span>Not applicable for this drawing.</span>
      </div>
    )}
    </>
  );
}

export default EditDrawingInstall;
