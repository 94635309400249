import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
  faTrash,
  faSquareCheck,
  faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import { debounce } from "lodash";
import { 
  listPurchaseOrders, 
  approvePO, 
  deletePO, 
  getBOMPOItemsForCommercialInvoice,
  generateCommercialInvoiceForPO
} from "../../store/reducer/drawing/drawingPurchaseOrderSlice";
import { getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import Notes from "../Notes/Notes";

function PurchaseOrderHistory() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    loading,
    purchaseOrders = [],
    totalCount,
  } = useSelector((state) => state.drawingPurchaseOrder);

  const { options = [] } = useSelector((state) => state.ddOptions);

  const [opportunityData, setOpportunityData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPOId, setSelectedPOId] = useState(null);
  const [poItems, setPoItems] = useState([]);
  const [truckSelections, setTruckSelections] = useState({});
  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false);

  const truckOptions = options.filter(
    (option) =>
      option.ui_name === "CommercialInvoice" &&
      option.field_name === "Trucks"
  );

  useEffect(() => {
    dispatch(getProposalFinalStatus(drawing_id)).then(result => {
      if (result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    });
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });
    dispatch(getDdOptionForUI());
    dispatch(listPurchaseOrders({ search, page, limit, drawing_id }));
  }, []);

  const handleApprovePO = async (po_id) => {
    await dispatch(approvePO(po_id)).then(async result => {
      if (result.payload) {
        await dispatch(listPurchaseOrders({ search, page, limit, drawing_id }));
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };
  
  const handleDeletePO = async (po_id) => {
    await dispatch(deletePO(po_id)).then(async result => {
      if (result.payload) {
        await dispatch(listPurchaseOrders({ search, page, limit, drawing_id }));
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const handleSearch = (search = "") => {
    dispatch(listPurchaseOrders({ search, page, limit, drawing_id }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [purchaseOrders]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listPurchaseOrders({ search, page, limit, drawing_id }));
  };

  const openModal = async (po_id) => {
    setSelectedPOId(po_id);
    const result = await dispatch(getBOMPOItemsForCommercialInvoice(po_id));
    if (result.payload) {
      setPoItems(result.payload.items || []);
      setTruckSelections({});
      setIsModalOpen(true);
    } else {
      toast.error("Failed to fetch PO items");
    }
  };

  const handleTruckSelection = (itemId, truck) => {
    setTruckSelections(prev => ({ ...prev, [itemId]: truck }));
  };

  const handleDefaultTruckSelection = (defaultTruck) => {
    if (defaultTruck) {
      const newSelections = {};
      poItems.forEach(item => {
        newSelections[item.id] = defaultTruck;
      });
      setTruckSelections(newSelections);
    }
  };

  const generateCommercialInvoice = async () => {
    setIsGeneratingInvoice(true);
    const groupedItemsByTruck = {};
    poItems.forEach(item => {
      const truckId = truckSelections[item.id];
      if (truckId) {
        if (!groupedItemsByTruck[truckId]) {
          groupedItemsByTruck[truckId] = [];
        }
        groupedItemsByTruck[truckId].push({
          id: item.id,
          item_description: item.item_description,
          qty: item.qty,
          item_length: item.item_length,
          revised_qty: item.revised_qty
        });
      }
    });

    if (Object.keys(groupedItemsByTruck).length === 0) {
      toast.error("Please assign at least one truck to an item.");
      setIsGeneratingInvoice(false);
      return;
    }

    const payload = {
      po_id: selectedPOId,
      trucks: groupedItemsByTruck
    };

    try {
      const result = await dispatch(generateCommercialInvoiceForPO(payload)).unwrap();
      toast.success("Commercial Invoice Generated Successfully");
      setIsModalOpen(false);
    } catch (error) {
      toast.error(`Failed to generate commercial invoice: ${error.message || "Unknown error"}`);
    } finally {
      setIsGeneratingInvoice(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Purchase Orders
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              PO History
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-purchaseorder-history" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Commercial Invoice
          </div>
        </Link>
      </div>
      {isProposalFinalised ? (
        <div className="flex flex-col mt-[20px] gap-[40px]">
          <div className="">
            <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
              <div className="flex flex-col">
                <div className="md:flex justify-between items-center p-[12px]">
                  <div className="relative">
                    <input
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="search"
                      name="search"
                      onChange={(e) => {
                        debouncedSearch(e.target.value);
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                      <img
                        src={process.env.PUBLIC_URL + "/icons/search.png"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 overflow-x-auto sm:rounded-lg">
                  <table className="w-full border-collapse">
                    <thead className="bg-white">
                      <tr className="rounded-[12px]">
                        {[
                          "Id",
                          "Area",
                          "Category",
                          "Supplier",
                          "Date",
                          "View PO",
                          "Options",
                          "Commercial Invoice"
                        ].map((header) => (
                          <th
                            key={header}
                            scope="col"
                            className="px-6 py-3 text-left font-[Inter] text-[14px] font-semibold text-[#6D6D6F] leading-[20px] tracking-wider whitespace-nowrap"
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {!loading ? (
                        purchaseOrders.map((purchaseOrder) => (
                          <tr
                            key={purchaseOrder.id}
                            className="border-b border-gray-200 hover:bg-gray-50"
                          >
                            <td className="px-6 py-4 text-[14px] font-semibold text-[#6D6D6F] whitespace-nowrap">
                              {purchaseOrder.id}
                            </td>
                            <td className="px-6 py-4 text-[14px] text-[#6D6D6F] whitespace-nowrap">
                              {purchaseOrder.area_name}
                            </td>
                            <td className="px-6 py-4 text-[14px] text-[#6D6D6F] whitespace-nowrap">
                              {purchaseOrder.category}
                            </td>
                            <td className="px-6 py-4 text-[14px] text-[#6D6D6F] whitespace-nowrap">
                              {purchaseOrder.company}
                            </td>
                            <td className="px-6 py-4 text-[14px] text-[#6D6D6F] whitespace-nowrap">
                              {new Date(purchaseOrder.createdAt).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </td>
                            <td className="px-6 py-4 text-[14px] font-medium whitespace-nowrap">
                              {purchaseOrder.po_file ? (
                                <a
                                  href={purchaseOrder.po_file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-dark-600 hover:underline"
                                >
                                  View
                                </a>
                              ) : (
                                <span className="text-gray-400">NA</span>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center justify-center gap-3">
                                {purchaseOrder.po_status === "draft" ? (
                                  <>
                                    <button
                                      onClick={() => handleApprovePO(purchaseOrder.id)}
                                      className="text-green-600 hover:text-green-800 transition-colors"
                                      title="Approve"
                                    >
                                      <FontAwesomeIcon icon={faSquareCheck} size="lg" />
                                    </button>
                                    <button
                                      onClick={() => handleDeletePO(purchaseOrder.id)}
                                      className="text-red-600 hover:text-red-800 transition-colors"
                                      title="Delete"
                                    >
                                      <FontAwesomeIcon icon={faTrash} size="lg" />
                                    </button>
                                  </>
                                ) : (
                                  <span className="text-[14px] text-[#6D6D6F] capitalize">
                                    {purchaseOrder.po_status}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {purchaseOrder.category.toLowerCase() === "bom" && (
                                <button
                                  onClick={() => openModal(purchaseOrder.id)}
                                  className={`transition-colors ${
                                    purchaseOrder.po_status === "approved"
                                      ? "text-blue-600 hover:text-blue-800"
                                      : "text-gray-400 cursor-not-allowed"
                                  }`}
                                  title="Commercial Invoice"
                                  disabled={purchaseOrder.po_status !== "approved"}
                                >
                                  <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8} className="py-8 text-center">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              size="2xl"
                              className="text-[#55A14A] animate-spin"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
            <div className="hidden md:block font-Inter font-[300] text-nowrap">
              <span>
                Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
                pages
              </span>
            </div>
            <div className="w-full flex justify-end">
              <ReactPaginate
                className="react_pagination_ul"
                breakLabel={
                  <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                    <FontAwesomeIcon icon={faEllipsis} size="xl" />
                  </span>
                }
                nextLabel={
                  <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                    <FontAwesomeIcon icon={faAngleRight} size="xl" />
                  </span>
                }
                onPageChange={handlePageClick}
                pageCount={Math.ceil(totalCount / limit)}
                previousLabel={
                  <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                    <FontAwesomeIcon icon={faAngleLeft} size="xl" />
                  </span>
                }
                activeClassName="bg-black text-white"
                pageClassName="block text-black hover:bg-black hover:text-white rounded-md mr-4"
                pageLinkClassName="w-10 h-10 flex justify-center items-center"
                containerClassName="flex items-center justify-center"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <span className="font-Inter font-[500] leading-[30px] text-[20px]">
            Please finalise proposal before viewing PO history.
          </span>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-5xl w-full max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">Commercial Invoice Items</h2>

            <div className="mb-4">
              <label htmlFor="defaultTruck" className="block text-sm font-medium text-gray-700 mb-2">
                Select Default Truck for All Items
              </label>
              <select
                id="defaultTruck"
                onChange={(e) => handleDefaultTruckSelection(e.target.value)}
                className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select Truck</option>
                {truckOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.field_code}
                  </option>
                ))}
              </select>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item Name</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item Length</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Quantity</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Truck</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {poItems.length > 0 ? (
                    poItems.map((item) => (
                      <tr key={item.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.item_description}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.qty}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.item_length}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.revised_qty}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <select
                            value={truckSelections[item.id] || ""}
                            onChange={(e) => handleTruckSelection(item.id, e.target.value)}
                            className="block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option value="">Select Truck</option>
                            {truckOptions.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="px-6 py-4 text-center text-sm text-gray-500">No items found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-6 flex justify-end gap-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={generateCommercialInvoice}
                className={`px-4 py-2 rounded-md transition-colors flex items-center gap-2 ${
                  isGeneratingInvoice || poItems.length === 0
                    ? "bg-blue-300 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                } text-white`}
                disabled={isGeneratingInvoice || poItems.length === 0}
              >
                {isGeneratingInvoice ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                    Generating...
                  </>
                ) : (
                  "Generate Commercial Invoice"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PurchaseOrderHistory;