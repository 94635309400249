import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faClone, faLock, faLockOpen, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getCustomerSitesByCustomerForUI } from "../../store/reducer/customers/customerSiteSlice";
import { getUserForUI } from "../../store/reducer/user/userSlice";
import {
  getOpportunityById,
  updateOpportunity,
} from "../../store/reducer/opportunity/opportunitySlice";
import { listDrawingsByOpportunity, copyDrawing, toggleDrawinglock } from "../../store/reducer/drawing/drawingSlice";

function EditOpportunity() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { options = [] } = useSelector((state) => state.ddOptions);
  const { loading, drawings = [] } = useSelector((state) => state.drawing);

  const [opportunityData, setOpportunityData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [customerData, setCustomerData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [dropdown, setDropdown] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDropdown = (drawing_id) => {
    setDropdown(drawing_id);
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    dispatch(
      listDrawingsByOpportunity({ opportunity_id: id, search, page, limit })
    );

    dispatch(getCustomerForUI()).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });

    dispatch(getUserForUI()).then((result) => {
      if (result.payload) {
        setUserData(result.payload);
      }
    });
    dispatch(getDdOptionForUI());
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
        dispatch(
          getCustomerSitesByCustomerForUI({
            customer_id: result.payload.customer_id,
          })
        ).then((result) => {
          if (result.payload) {
            setSiteData(result.payload);
          }
        });
        setDataLoading(false);
      }
    });
  }, []);

  const getDefaultOption = (ui_name, field_name) => {
    const defaultOption = options.find((option) => {
      return (
        option.ui_name === ui_name &&
        option.field_name === field_name &&
        option.is_default === true
      );
    });
    return defaultOption ? defaultOption.id : "";
  };

  const validationSchema = Yup.object().shape({
    customer_id: Yup.string().required("please select customer"),
    site_id: Yup.string().required("please select site"),
    opportunity_name: Yup.string().required("please enter opportunity name"),
    sales_executive: Yup.string().required("please select sales executive"),
    site_status: Yup. string().required("please select site status")
  });

  const initialValues = {
    customer_id: opportunityData?.customer_id || "",
    site_id: opportunityData?.site_id || "",
    opportunity_name: opportunityData?.opportunity_name || "",
    sales_executive: opportunityData?.sales_executive || "",
    first_contact_date: opportunityData?.first_contact_date || "",
    expected_close_date: opportunityData?.expected_close_date || "",
    last_contact_date: opportunityData?.last_contact_date || "",
    next_contact_date: opportunityData?.next_contact_date || "",
    estimated_value: opportunityData?.estimated_value || "",
    lead_source:
      opportunityData?.lead_source ||
      getDefaultOption("Opportunity", "Lead_Source") ||
      "",
    current_status_comment: opportunityData?.current_status_comment || "",
    job_type: opportunityData?.job_type || "",
    progress_status: opportunityData?.progress_status || "",
    win_probability: opportunityData?.win_probability || "",
    site_status: opportunityData?.site_status || "",
    status: opportunityData?.status || "Active",
  };

  const getSiteByCustomerId = (customer_id) => {
    dispatch(getCustomerSitesByCustomerForUI({ customer_id })).then(
      (result) => {
        if (result.payload) {
          setSiteData(result.payload);
        }
      }
    );
  };

  const handleCopyDrawing = async (drawing_id) => {
    await dispatch(copyDrawing(drawing_id)).then(async result => {
      if (result.payload) {
        await dispatch(
          listDrawingsByOpportunity({ opportunity_id: id, search, page, limit })
        );
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    })
  }

  const handleToggleDrawingLock = async (drawing_id) => {
    await dispatch(toggleDrawinglock(drawing_id)).then(async result => {
      if (result.payload) {
        await dispatch(
          listDrawingsByOpportunity({ opportunity_id: id, search, page, limit })
        );
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    })
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    if (values.progress_status === "148" || values.progress_status === "175") {
      values.expected_close_date = moment().format("yyyy-MM-DD");
      values.next_contact_date = moment().format("yyyy-MM-DD");
    }
    if (values.progress_status === "81" || values.progress_status === "157") {
      values.next_contact_date = moment().format("yyyy-MM-DD");
    }
    if (values.progress_status === "84" || values.progress_status === "85") {
      values.next_contact_date = moment()
        .add(getDefaultOption("Opportunity", "Expected_Close_Date"), "days")
        .format("yyyy-MM-DD");
    }
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateOpportunity({ data: formData, id })).then((result) => {
      if (result.payload) {
        navigate(`/opportunity`);
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/opportunity">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Opportunity / Edit Opportunity
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Edit Opportunity
            </span>
          </div>
        </div>
      </div>
      <div className="block md:flex gap-[20px]">
        <div className="w-[100%] md:w-[40%]">
          {!dataLoading ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                isSubmitting,
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form>
                  <div className="md:grid md:grid-cols-2 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6">
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="customer_id"
                      >
                        Customer
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="customer_id"
                          name="customer_id"
                          onChange={(e) => {
                            setFieldValue("customer_id", e.target.value);
                            getSiteByCustomerId(e.target.value);
                          }}
                        >
                          <option value={""}>Select Customer</option>
                          {customerData.map((customer) => (
                            <option value={customer.id} key={customer.id}>
                              {customer.customer_name}
                            </option>
                          ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.customer_id && touched.customer_id ? (
                        <div className="text-red-700">{errors.customer_id}</div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="site_id"
                      >
                        Site
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="site_id"
                          name="site_id"
                          onChange={(e) => {
                            setFieldValue("site_id", e.target.value);
                          }}
                        >
                          <option value={""}>Select Site</option>
                          {siteData.map((site) => (
                            <option value={site.id} key={site.id}>
                              {site.site_name}
                            </option>
                          ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.site_id && touched.site_id ? (
                        <div className="text-red-700">{errors.site_id}</div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="opportunity_name"
                      >
                        Opportunity Name
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        id="opportunity_name"
                        name="opportunity_name"
                        onChange={(e) => {
                          setFieldValue("opportunity_name", e.target.value);
                        }}
                      />
                      {errors.opportunity_name && touched.opportunity_name ? (
                        <div className="text-red-700">
                          {errors.opportunity_name}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="sales_executive"
                      >
                        Sales Executive
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="sales_executive"
                          name="sales_executive"
                          onChange={(e) => {
                            setFieldValue("sales_executive", e.target.value);
                          }}
                        >
                          <option value={""}>Select Sales Executive</option>
                          {userData.map((user) => (
                            <option value={user.id} key={user.id}>
                              {user.firstname} {user.lastname}
                            </option>
                          ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.sales_executive && touched.sales_executive ? (
                        <div className="text-red-700">
                          {errors.sales_executive}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="first_contact_date"
                      >
                        First Contact Date
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="first_contact_date"
                        name="first_contact_date"
                        disabled
                        onChange={(e) => {
                          setFieldValue("first_contact_date", e.target.value);
                        }}
                      />
                      {errors.first_contact_date &&
                      touched.first_contact_date ? (
                        <div className="text-red-700">
                          {errors.first_contact_date}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="expected_close_date"
                      >
                        Expected Close Date
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="expected_close_date"
                        name="expected_close_date"
                        onChange={(e) => {
                          setFieldValue("expected_close_date", e.target.value);
                        }}
                      />
                      {errors.expected_close_date &&
                      touched.expected_close_date ? (
                        <div className="text-red-700">
                          {errors.expected_close_date}
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="last_contact_date"
                      >
                        Last Contact Date
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="last_contact_date"
                        name="last_contact_date"
                        onChange={(e) => {
                          setFieldValue("last_contact_date", e.target.value);
                        }}
                      />
                      {errors.last_contact_date && touched.last_contact_date ? (
                        <div className="text-red-700">
                          {errors.last_contact_date}
                        </div>
                      ) : null}
                    </div> */}
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="next_contact_date"
                      >
                        Next Contact Date
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="next_contact_date"
                        name="next_contact_date"
                        onChange={(e) => {
                          setFieldValue("next_contact_date", e.target.value);
                        }}
                      />
                      {errors.next_contact_date && touched.next_contact_date ? (
                        <div className="text-red-700">
                          {errors.next_contact_date}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="estimated_value"
                      >
                        Total Estimated Value
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        id="estimated_value"
                        name="estimated_value"
                        onChange={(e) => {
                          setFieldValue("estimated_value", e.target.value);
                        }}
                      />
                      {errors.estimated_value && touched.estimated_value ? (
                        <div className="text-red-700">
                          {errors.estimated_value}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="lead_source"
                      >
                        Lead Source
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="lead_source"
                          name="lead_source"
                          value={values.lead_source}
                          onChange={(e) => {
                            setFieldValue("lead_source", e.target.value);
                          }}
                        >
                          <option value={""}>Select Lead Source</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Opportunity" &&
                                option.field_name === "Lead_Source"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.lead_source && touched.lead_source ? (
                        <div className="text-red-700">{errors.lead_source}</div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="job_type"
                      >
                        Job Type
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="job_type"
                          name="job_type"
                          value={values.job_type}
                          onChange={(e) => {
                            setFieldValue("job_type", e.target.value);
                          }}
                        >
                          <option value={""}>Select Job Type</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Opportunity" &&
                                option.field_name === "Job_Type"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.job_type && touched.job_type ? (
                        <div className="text-red-700">{errors.job_type}</div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="progress_status"
                      >
                        Progress Status
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="progress_status"
                          name="progress_status"
                          value={values.progress_status}
                          onChange={(e) => {
                            setFieldValue("progress_status", e.target.value);
                          }}
                        >
                          <option value={""}>Select Progress Status</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Opportunity" &&
                                option.field_name === "Progress_Status"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.progress_status && touched.progress_status ? (
                        <div className="text-red-700">
                          {errors.progress_status}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="win_probability"
                      >
                        Win Probability
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="win_probability"
                          name="win_probability"
                          value={values.win_probability}
                          onChange={(e) => {
                            setFieldValue("win_probability", e.target.value);
                          }}
                        >
                          <option value={""}>Select Win Probability</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Opportunity" &&
                                option.field_name === "Win_Probability"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.win_probability && touched.win_probability ? (
                        <div className="text-red-700">
                          {errors.win_probability}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="site_status"
                      >
                        Site Status
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="site_status"
                          name="site_status"
                          onChange={(e) => {
                            setFieldValue("site_status", e.target.value);
                          }}
                        >
                          <option value={""}>Select Site Status</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Opportunity" &&
                                option.field_name === "Site_Status"
                            )
                            .map((option) => (
                              <option
                                key={option.id}
                                value={option.id}
                                selected={option.is_default ? true : false}
                              >
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.site_status && touched.site_status ? (
                        <div className="text-red-700">{errors.site_status}</div>
                      ) : null}
                    </div>
                    <div className="mb-2 col-span-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="current_status_comment"
                      >
                        Opportunity Notes
                      </label>
                      <Field
                        as="textarea"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500  h-[200px]"
                        type="text"
                        id="current_status_comment"
                        name="current_status_comment"
                        onChange={(e) => {
                          setFieldValue(
                            "current_status_comment",
                            e.target.value
                          );
                        }}
                      />
                      {errors.current_status_comment &&
                      touched.current_status_comment ? (
                        <div className="text-red-700">
                          {errors.current_status_comment}
                        </div>
                      ) : null}
                    </div>
                    {/* <div>
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="status"
                      >
                        Status
                      </label>
                      <div>
                        <label
                          htmlFor="status"
                          className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                        >
                          <input
                            id="status"
                            type="checkbox"
                            className="hidden peer"
                            name="status"
                            defaultChecked={values.status === "Active"}
                            onChange={(e) => {
                              const checkedValue = e.target.checked
                                ? "Active"
                                : "Inactive";
                              setFieldValue("status", checkedValue);
                            }}
                          />
                          <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                            Inactive
                          </span>
                          <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                            Active
                          </span>
                        </label>
                      </div>
                    </div> */}
                  </div>
                  <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                    <div>
                      <button
                        type="submit"
                        name="submit"
                        className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                          isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                        }`}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="text-center">
              <FontAwesomeIcon
                icon={faSpinner}
                size="2xl"
                style={{ color: "#55A14A" }}
                spin
              />
            </div>
          )}
        </div>
        <div className="w-[100%] md:w-[60%]">
          <div className="block md:flex justify-between items-center p-[12px]">
            <div className="mb-[16px] md:mb-0">
              <span className="font-Inter font-[500] text-[24px] leading-[30px] text-black">
                Drawings
              </span>
            </div>
            <div className="">
              <Link to={`/opportunity/${id}/drawing/request`}>
                <div className="py-[10px] px-[40px] font-[500] font-Inter text-[18px] justify-center text-[#17171B] rounded-[12px] text-[#55A14A] border border-[#55A14A] w-max">
                  <span>Request Drawing</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px] overflow-scroll">
            <div className="flex flex-col">
              <div className="sm:rounded-lg mt-5">
                <table className="w-full">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Created By
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Requested On
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Completion Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider"
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {!loading ? (
                      drawings.map((drawing) => (
                        <tr key={drawing.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                            {drawing.id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {drawing.requested_by_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {drawing.date_requested}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {drawing.date_required}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            <span className="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full text-black-800">
                              {drawing.status_name}
                            </span>
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap text-right font-medium">
                            <div className="w-[160px] flex items-center justify-end gap-[12px]">
                              {/* Ellipsis Dropdown Trigger */}
                              <div className="relative">
                                <img
                                  src={process.env.PUBLIC_URL + "/icons/elipses.png"}
                                  alt="More options"
                                  className="w-[40px] h-[40px] bg-white rounded-lg cursor-pointer object-contain p-2"
                                  onClick={() => toggleDropdown(drawing.id)}
                                />
                                {showDropdown && dropdown === drawing.id ? (
                                  <div className="absolute w-[280px] bg-white h-[400px] overflow-y-auto right-0 top-[48px] p-4 text-left rounded-[12px] shadow-lg z-10">
                                    <Link to={`/opportunity/${id}/drawing/${drawing.id}/site`}>
                                      <div className="rounded-[8px] p-[12px] hover:bg-[#55A14A] text-[#6A6A6A] hover:text-white">
                                        <span className="font-Inter text-[16px] font-[500] leading-[20px]">
                                          Site Details
                                        </span>
                                      </div>
                                    </Link>
                                    <Link to={`/opportunity/${id}/drawing/${drawing.id}/take-off`}>
                                      <div className="rounded-[8px] p-[12px] hover:bg-[#55A14A] text-[#6A6A6A] hover:text-white">
                                        <span className="font-Inter text-[16px] font-[500] leading-[20px]">
                                          Take Off
                                        </span>
                                      </div>
                                    </Link>
                                    <Link to={`/opportunity/${id}/drawing/${drawing.id}/bom`}>
                                      <div className="rounded-[8px] p-[12px] hover:bg-[#55A14A] text-[#6A6A6A] hover:text-white">
                                        <span className="font-Inter text-[16px] font-[500] leading-[20px]">
                                          BOM
                                        </span>
                                      </div>
                                    </Link>
                                    <Link to={`/opportunity/${id}/drawing/${drawing.id}/install`}>
                                      <div className="rounded-[8px] p-[12px] hover:bg-[#55A14A] text-[#6A6A6A] hover:text-white">
                                        <span className="font-Inter text-[16px] font-[500] leading-[20px]">
                                          Install
                                        </span>
                                      </div>
                                    </Link>
                                    <Link to={`/opportunity/${id}/drawing/${drawing.id}/additional-options`}>
                                      <div className="rounded-[8px] p-[12px] hover:bg-[#55A14A] text-[#6A6A6A] hover:text-white">
                                        <span className="font-Inter text-[16px] font-[500] leading-[20px]">
                                          Additional Options
                                        </span>
                                      </div>
                                    </Link>
                                    <Link onClick={openModal}>
                                      <div className="rounded-[8px] p-[12px] hover:bg-[#55A14A] text-[#6A6A6A] hover:text-white">
                                        <span className="font-Inter text-[16px] font-[500] leading-[20px]">
                                          Copy Drawing
                                        </span>
                                      </div>
                                    </Link>
                                    <Link to={`/opportunity/${id}/drawing/${drawing.id}/discount`}>
                                      <div className="rounded-[8px] p-[12px] hover:bg-[#55A14A] text-[#6A6A6A] hover:text-white">
                                        <span className="font-Inter text-[16px] font-[500] leading-[20px]">
                                          Discount
                                        </span>
                                      </div>
                                    </Link>
                                    <Link to={`/opportunity/${id}/drawing/${drawing.id}/pandl`}>
                                      <div className="rounded-[8px] p-[12px] hover:bg-[#55A14A] text-[#6A6A6A] hover:text-white">
                                        <span className="font-Inter text-[16px] font-[500] leading-[20px]">
                                          P&L
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                ) : null}
                              </div>

                              {/* Edit Icon */}
                              <div>
                                <Link to={`/opportunity/${id}/drawing/${drawing.id}`}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </Link>
                              </div>

                              {/* Delete Icon */}
                              <div>
                                <FontAwesomeIcon icon={faTrash} />
                              </div>

                                {/* Clone Icon */}
                                <div>
                                  <button onClick={() => handleCopyDrawing(drawing.id)} className="text-black-600">
                                    <FontAwesomeIcon icon={faClone} />
                                  </button>
                                </div>

                                {/* Lock/Unlock Icon */}
                                <div>
                                  <button onClick={() => handleToggleDrawingLock(drawing.id)} className="text-black-600">
                                    <FontAwesomeIcon icon={(drawing && drawing.locked) ? faLock : faLockOpen } />
                                  </button>
                                </div>
                            </div>

                            {/* Modal */}
                            {isModalOpen && (
                              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                <div className="bg-white p-4 rounded shadow-lg w-96 relative">
                                  <button
                                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-xl"
                                    onClick={closeModal}
                                  >
                                    ×
                                  </button>
                                  <h2 className="text-xl font-bold mb-4">Modal Title</h2>
                                  <p className="mb-4">This is the modal content.</p>
                                  <button
                                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                    onClick={closeModal}
                                  >
                                    Close Modal
                                  </button>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              size="2xl"
                              style={{ color: "#55A14A" }}
                              spin
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditOpportunity;
