import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ExpenseReport from "./ExpenseReport";
import MaterialListReport from "./MaterialListReport";

const Reports = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get("tab");

  const [activeTab, setActiveTab] = useState("expense-report");

  useEffect(() => {
    if (tabParam === "expense-report") {
      setActiveTab("expense-report");
    }
  }, [tabParam]);

  return (
    <div className="min-h-screen">
      {/* Tabs Navigation */}
      <div className="flex border-b-2 border-gray-200">
        <button
          className={`px-6 py-2 mx-4 border-b-4 transition duration-300 ${
            activeTab === "expense-report"
              ? "border-green-600 text-green-600 font-bold"
              : "border-transparent text-gray-500 hover:text-green-600"
          }`}
          onClick={() => setActiveTab("expense-report")}
        >
          Expense Report
        </button>
        <button
          className={`px-6 py-2 mx-4 border-b-4 transition duration-300 ${
            activeTab === "material-list"
              ? "border-green-600 text-green-600 font-bold"
              : "border-transparent text-gray-500 hover:text-green-600"
          }`}
          onClick={() => setActiveTab("material-list")}
        >
          Material List
        </button>
      </div>

      {/* Tab Content */}
      <div className="mt-4 mx-4">
        <div className="bg-white shadow-md rounded-lg p-6">
          {activeTab === "expense-report" && <ExpenseReport />}
          {activeTab === "material-list" && <MaterialListReport />}
        </div>
      </div>
    </div>
  );
};

export default Reports;
