import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthUser } from "./store/reducer/auth/authSlice";
import MainLayout from "./components/Layout/MainLayout";
import PrivateRoutes from "./middleware/PrivateRoute";

import Login from "./pages/Auth/Login";
import ListOpportunity from "./pages/Opportunity/ListOpportunity";
import UserList from "./pages/Settings/User/ListUser";
import Dashboard from "./pages/Dashboard";
import SettingList from "./pages/Settings/SettingList";
import AddOpportunity from "./pages/Opportunity/AddOpportunity";
import EditOpportunity from "./pages/Opportunity/EditOpportunity";
import AddUser from "./pages/Settings/User/AddUser";
import EditUser from "./pages/Settings/User/EditUser";
import ListDdOption from "./pages/Settings/DdOptions/ListDdOption";
import AddDdOption from "./pages/Settings/DdOptions/AddDdOption";
import EditDdOption from "./pages/Settings/DdOptions/EditDdOption";
import ListItem from "./pages/Settings/Items/ListItem";
import AddItem from "./pages/Settings/Items/AddItem";
import EditItem from "./pages/Settings/Items/EditItem";
import ListTaxRate from "./pages/Settings/Finance/TaxRate/ListTaxRate";
import ListSupplier from "./pages/Settings/Supplier/ListSupplier";
import AddSupplier from "./pages/Settings/Supplier/AddSupplier";
import EditSupplier from "./pages/Settings/Supplier/EditSupplier";
import EditBillingShipping from "./pages/Settings/Supplier/EditBillingShipping";
import ListSupplierContact from "./pages/Settings/Supplier/ListSupplierContact";
import AddSupplierContact from "./pages/Settings/Supplier/AddSupplierContact";
import EditSupplierContact from "./pages/Settings/Supplier/EditSupplierContact";
import ListItemPrice from "./pages/Settings/ItemPrices/ListItemPrices";
import AddItemPrice from "./pages/Settings/ItemPrices/AddItemPrice";
import EditItemPrice from "./pages/Settings/ItemPrices/EditItemPrice";
import ListCustomer from "./pages/Customer/ListCustomer";
import AddCustomer from "./pages/Customer/AddCustomer";
import EditCustomer from "./pages/Customer/EditCustomer";
import ListSite from "./pages/Customer/ListSite";
import AddSite from "./pages/Customer/AddSite";
import EditSite from "./pages/Customer/EditSite";
import ListCustomerContact from "./pages/Customer/ListCustomerContact";
import AddCustomerContact from "./pages/Customer/AddCustomerContact";
import EditCustomerContact from "./pages/Customer/EditCustomerContact";
import AddDrawing from "./pages/Drawing/AddDrawing";
import EditDrawing from "./pages/Drawing/EditDrawing";
import EditDrawingSiteDetail from "./pages/Drawing/EditDrawingSiteDetail";
import EditDrawingTakeOff from "./pages/Drawing/EditDrawingTakeOff";
import EditDrawingBom from "./pages/Drawing/EditDrawingBom";
import PurchaseOrders from "./pages/Drawing/PurchaseOrders";
import ListTakeOffItem from "./pages/Settings/Takeoff Items/ListTakeOffItem";
import AddTakeOffItem from "./pages/Settings/Takeoff Items/AddTakeOffItem";
import EditTakeOffItem from "./pages/Settings/Takeoff Items/EditTakeOffItem";
import ListDrawings from "./pages/Drawing/ListDrawings";
import EditDrawingInstall from "./pages/Drawing/EditDrawingInstall";
import AddDrawingFiles from "./pages/Drawing/AddDrawingFiles";
import ListExpense from "./pages/Expenses/ListExpense";
import AddExpense from "./pages/Expenses/AddExpense";
import EditExpense from "./pages/Expenses/EditExpense";
import EditDrawingDiscount from "./pages/Drawing/EditDrawingDiscount";
import EditDrawingAdditionalOption from "./pages/Drawing/EditDrawingAdditionalOption";
import ListOptionCosts from "./pages/Settings/OptionCosts/ListOptionCosts";
import AddOptionCosts from "./pages/Settings/OptionCosts/AddOptionCosts";
import EditOptionCosts from "./pages/Settings/OptionCosts/EditOptionCosts";
import EditDrawingPandL from "./pages/Drawing/EditDrawingPandL";
import OpportunityDataReport from "./pages/Reports/OpportunityDataReport";
import ProposalFiles from "./pages/Settings/ProposalFiles/ProposalFiles";
import EditDrawingProposal from "./pages/Drawing/EditDrawingProposal";
import ListInvoices from "./pages/Invoices/ListInvoices";
import ListBills from "./pages/Bills/ListBills";
import SourceAnalysis from "./pages/Reports/SourceAnalysis";
import PurchaseOrderHistory from "./pages/Drawing/PurchaseOrderHistory";
import AddBusinessMileage from "./pages/BusinessMileage/AddBusinessMileage";
import ListBusinessMileage from "./pages/BusinessMileage/ListBusinessMileage";
import EditBusinessMileage from "./pages/BusinessMileage/EditBusinessMileage";
import Financials from "./pages/Financials/Financials";
import AddCarMileage from "./pages/CarMileage/AddCarMileage";
import ListCarMileage from "./pages/CarMileage/ListCarMileage";
import EditCarMileage from "./pages/CarMileage/EditCarMileage";
import ListBankTransactions from "./pages/BankTransactions/ListBankTransactions";
import Mileage from "./pages/Mileage/Mileage";
import ListExpensePeriod from "./pages/Settings/ExpensePeriod/ListExpensePeriod";
import AddExpensePeriod from "./pages/Settings/ExpensePeriod/AddExpensePeriod";
import EditExpensePeriod from "./pages/Settings/ExpensePeriod/EditExpensePeriod";
import ResetPassword from "./pages/Auth/ResetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import AddBusinessTimeEntry from "./pages/BusinessTimeEntry/AddBusinessTimeEntry";
import EditBusinessTimeEntry from "./pages/BusinessTimeEntry/EditBusinessTimeEntry";
import ListBusinessTimeEntry from "./pages/BusinessTimeEntry/ListBusinessTimeEntry";
import ExpenseReport from "./pages/Reports/ExpenseReport";
import Reports from "./pages/Reports/Reports";
import CommercialInvoice from "./pages/Drawing/CommercialInvoice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const local_data = JSON.parse(localStorage.getItem("adminparticipant"));
    if (local_data && local_data.token) {
      dispatch(setAuthUser(local_data));
    }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route element={<PrivateRoutes />}>
        <Route
          path="/dashboard"
          element={<MainLayout children={<Dashboard />} />}
        />
        <Route
          path="/opportunity"
          element={<MainLayout children={<ListOpportunity />} />}
        />
        <Route
          path="/opportunity/add"
          element={<MainLayout children={<AddOpportunity />} />}
        />
        <Route
          path="/opportunity/:id"
          element={<MainLayout children={<EditOpportunity />} />}
        />
        {/* Drawing Route */}
        <Route
          path="/drawings"
          element={<MainLayout children={<ListDrawings />} />}
        />
        <Route
          path="/opportunity/:id/drawing/request"
          element={<MainLayout children={<AddDrawing />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id"
          element={<MainLayout children={<EditDrawing />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/site"
          element={<MainLayout children={<EditDrawingSiteDetail />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/take-off"
          element={<MainLayout children={<EditDrawingTakeOff />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/bom"
          element={<MainLayout children={<EditDrawingBom />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/install"
          element={<MainLayout children={<EditDrawingInstall />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/additional-options"
          element={<MainLayout children={<EditDrawingAdditionalOption />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/purchase-orders"
          element={<MainLayout children={<PurchaseOrders />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/purchase-orders/history"
          element={<MainLayout children={< PurchaseOrderHistory/>} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/files"
          element={<MainLayout children={<AddDrawingFiles />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/discount"
          element={<MainLayout children={<EditDrawingDiscount />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/pandl"
          element={<MainLayout children={<EditDrawingPandL />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/proposal"
          element={<MainLayout children={<EditDrawingProposal />} />}
        />
        {/* Setting Route starts here*/}
        <Route
          path="/setting"
          element={<MainLayout children={<SettingList />} />}
        />
        <Route
          path="/setting/user"
          element={<MainLayout children={<UserList />} />}
        />
        <Route
          path="/setting/user/add"
          element={<MainLayout children={<AddUser />} />}
        />
        <Route
          path="/setting/user/:id"
          element={<MainLayout children={<EditUser />} />}
        />
        {/* dropdown Route starts here*/}
        <Route
          path="/setting/dd-option"
          element={<MainLayout children={<ListDdOption />} />}
        />
        <Route
          path="/setting/dd-option/add"
          element={<MainLayout children={<AddDdOption />} />}
        />
        <Route
          path="/setting/dd-option/:id"
          element={<MainLayout children={<EditDdOption />} />}
        />
        {/* Item Route starts here*/}
        <Route
          path="/setting/item"
          element={<MainLayout children={<ListItem />} />}
        />
        <Route
          path="/setting/item/add"
          element={<MainLayout children={<AddItem />} />}
        />
        <Route
          path="/setting/item/:id"
          element={<MainLayout children={<EditItem />} />}
        />
        {/* Takeoff Item Route starts here*/}
        <Route
          path="/setting/takeoff/item"
          element={<MainLayout children={<ListTakeOffItem />} />}
        />
        <Route
          path="/setting/takeoff/item/add"
          element={<MainLayout children={<AddTakeOffItem />} />}
        />
        <Route
          path="/setting/takeoff/item/:id"
          element={<MainLayout children={<EditTakeOffItem />} />}
        />
        {/* Tax rate Route starts here*/}
        <Route
          path="/setting/finance/taxrate"
          element={<MainLayout children={<ListTaxRate />} />}
        />
        <Route
          path="/setting/item/add"
          element={<MainLayout children={<AddItem />} />}
        />
        <Route
          path="/setting/item/:id"
          element={<MainLayout children={<EditItem />} />}
        />

        {/* Supplier Route starts here*/}
        <Route
          path="/setting/supplier"
          element={<MainLayout children={<ListSupplier />} />}
        />
        <Route
          path="/setting/supplier/add"
          element={<MainLayout children={<AddSupplier />} />}
        />
        <Route
          path="/setting/supplier/:id"
          element={<MainLayout children={<EditSupplier />} />}
          exact
        />
        <Route
          path="/setting/supplier/:id/billing"
          element={<MainLayout children={<EditBillingShipping />} />}
          exact
        />
        <Route
          path="/setting/supplier/:id/contact"
          element={<MainLayout children={<ListSupplierContact />} />}
          exact
        />
        <Route
          path="/setting/supplier/:id/contact/add"
          element={<MainLayout children={<AddSupplierContact />} />}
          exact
        />
        <Route
          path="/setting/supplier/:id/contact/:contact_id"
          element={<MainLayout children={<EditSupplierContact />} />}
          exact
        />
        {/* Item price route starts here */}
        <Route
          path="/setting/item-price"
          element={<MainLayout children={<ListItemPrice />} />}
        />
        <Route
          path="/setting/item-price/add"
          element={<MainLayout children={<AddItemPrice />} />}
        />
        <Route
          path="/setting/item-price/:id"
          element={<MainLayout children={<EditItemPrice />} />}
        />
        {/* Expense period route starts here */}
        <Route
          path="/setting/expense-period"
          element={<MainLayout children={<ListExpensePeriod />} />}
        />
        <Route
          path="/setting/expense-period/add"
          element={<MainLayout children={<AddExpensePeriod />} />}
        />
        <Route
          path="/setting/expense-period/:id"
          element={<MainLayout children={<EditExpensePeriod />} />}
        />
        {/* Files route start here */}
        <Route
          path="/setting/proposal-files"
          element={<MainLayout children={<ProposalFiles />} />}
        />
        {/* Customer route starts here */}
        <Route
          path="/customers"
          element={<MainLayout children={<ListCustomer />} />}
        />
        <Route
          path="/customer/add"
          element={<MainLayout children={<AddCustomer />} />}
        />
        <Route
          path="/customer/:id"
          element={<MainLayout children={<EditCustomer />} />}
        />
        <Route
          path="/customer/:id/site"
          element={<MainLayout children={<ListSite />} />}
        />
        <Route
          path="/customer/:id/site/add"
          element={<MainLayout children={<AddSite />} />}
        />
        <Route
          path="/customer/:id/site/:site_id"
          element={<MainLayout children={<EditSite />} />}
        />
        <Route
          path="/customer/:id/contacts"
          element={<MainLayout children={<ListCustomerContact />} />}
        />
        <Route
          path="/customer/:id/contact/add"
          element={<MainLayout children={<AddCustomerContact />} />}
        />
        <Route
          path="/customer/:id/contact/:contact_id"
          element={<MainLayout children={<EditCustomerContact />} />}
        />

        {/* Expense route starts here */}
        <Route
          path="/expenses"
          element={<MainLayout children={<ListExpense />} />}
        />
        <Route
          path="/expense/add"
          element={<MainLayout children={<AddExpense />} />}
        />
        <Route
          path="/expense/:id"
          element={<MainLayout children={<EditExpense />} />}
        />

        {/* Expense route starts here */}
        <Route
          path="/setting/option-costs"
          element={<MainLayout children={<ListOptionCosts />} />}
        />
        <Route
          path="/setting/option-costs/add"
          element={<MainLayout children={<AddOptionCosts />} />}
        />
        <Route
          path="/setting/option-costs/:id"
          element={<MainLayout children={<EditOptionCosts />} />}
        />

        {/* Reports route starts here */}
        <Route
          path="reports"
          element={<MainLayout children={<Reports />} />}
        />
        
        {/* Invoices route starts here */}
        <Route
          path="/invoices"
          element={<MainLayout children={<ListInvoices />} />}
        />
        <Route
          path="/bills"
          element={<MainLayout children={<ListBills />} />}
        />
        <Route
          path="/business-mileage/add"
          element={<MainLayout children={<AddBusinessMileage />} />}
        />
        <Route
          path="/business-mileage/:id"
          element={<MainLayout children={<EditBusinessMileage />} />}
        />
        <Route
          path="/financials"
          element={<MainLayout children={<Financials />} />}
        />
        <Route
          path="/mileage"
          element={<MainLayout children={<Mileage />} />}
        />
        <Route
          path="/car-mileage/add"
          element={<MainLayout children={<AddCarMileage />} />}
        />
        <Route
          path="/car-mileage/:id"
          element={<MainLayout children={<EditCarMileage />} />}
        />
        <Route
          path="/bank-transactions"
          element={<MainLayout children={<ListBankTransactions />} />}
        />
        <Route
          path="/time-entries/add"
          element={<MainLayout children={<AddBusinessTimeEntry />} />}
        />
        <Route
          path="/time-entries/:id"
          element={<MainLayout children={<EditBusinessTimeEntry />} />}
        />
        <Route
          path="/time-entries"
          element={<MainLayout children={<ListBusinessTimeEntry />} />}
        />
        <Route
          path="/opportunity/:id/drawing/:drawing_id/commercial-invoice"
          element={<MainLayout children={<CommercialInvoice />} />}
        />
      </Route>
    </Routes>
  );
}

export default App;
