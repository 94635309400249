import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const copyPOData = createAsyncThunk(
  "copyPOData",
  async (data) => {
    const request = await api(`api/admin/copypodata/${data}`, "", "get");
    return request;
  }
);

export const getBomSuppliers = createAsyncThunk(
  "getBomSuppliers",
  async (data) => {
    const request = await api(`api/admin/bomsuppliers/${data}`, "", "get");
    return request;
  }
);

export const getInstallSuppliers = createAsyncThunk(
  "getInstallSuppliers",
  async (data) => {
    const request = await api(`api/admin/installsuppliers/${data}`, "", "get");
    return request;
  }
);

export const getAdditionalOptionSuppliers = createAsyncThunk(
  "getAdditionalOptionSuppliers",
  async (data) => {
    const request = await api(`api/admin/additional-options-suppliers/${data}`, "", "get");
    return request;
  }
);

export const getAdditionalOptionItems = createAsyncThunk(
  "getAdditionalOptionItems",
  async (data) => {
    const request = await api(`api/admin/additional-options-items/${data.drawing_id}`, "", "get");
    return request;
  }
);

export const updateAddOptPOItem = createAsyncThunk(
  "updateAddOptPOItem",
  async ({ data, id }) => {
    const request = await api(`api/admin/addoptpo/${id}`, data, "post");
    return request;
  }
);

export const getBomItems = createAsyncThunk(
  "getBomItems",
  async ({ drawing_id, supplier_id }) => {
    const request = await api(`api/admin/bomitems/${drawing_id}/${supplier_id}`, "", "get");
    return request;
  }
);

export const deleteBomPOItem = createAsyncThunk(
  "deleteBomPOItem",
  async ({ id }) => {
    const request = await api(`api/admin/bompo/${id}`, {}, "delete");
    return request;
  }
);

export const updateBomPOItem = createAsyncThunk(
  "updateBomPOItem",
  async ({ data, id }) => {
    const request = await api(`api/admin/bompo/${id}`, data, "post");
    return request;
  }
);

export const addBomPOItem = createAsyncThunk(
  "addBomPOItem",
  async ({ data, id }) => {
    const request = await api(`api/admin/bompo`, data, "post");
    return request;
  }
);
export const getInstallItems = createAsyncThunk(
  "getInstallItems",
  async (data) => {
    const request = await api(`api/admin/installitems/${data.drawing_id}`, "", "get");
    return request;
  }
);

export const updateInstallPOItem = createAsyncThunk(
  "updateInstallPOItem",
  async ({ data, id }) => {
    const request = await api(`api/admin/installpo/${id}`, data, "post");
    return request;
  }
);

export const deleteInstallPOItem = createAsyncThunk(
  "deleteInstallPOItem",
  async ({ id }) => {
    const request = await api(`api/admin/installpo/${id}`, {}, "delete");
    return request;
  }
);

export const createPurchaseOrder = createAsyncThunk(
  "createPurchaseOrder",
  async ({ data }) => {
    const request = await api(`api/admin/purchaseorder`, data, "post");
    return request;
  }
);

export const listPurchaseOrders = createAsyncThunk(
  "listPurchaseOrders",
  async ({ search = "", page = 1, limit = 10, drawing_id }) => {
    const request = await api(
      `api/admin/purchaseorder?search=${search}&page=${page}&limit=${limit}&drawing_id=${drawing_id}`,
      "",
      "get"
    );
    return request;
  }
);

export const approvePO = createAsyncThunk(
  "approvePO",
  async (po_id) => {
    const request = await api(`api/admin/approvepo/${po_id}`, {}, "get");
    return request;
  }
);


export const deletePO = createAsyncThunk(
  "deletePO",
  async (po_id) => {
    const request = await api(`api/admin/deletepo/${po_id}`, {}, "delete");
    return request;
  }
);

export const getPOApprovedStatus = createAsyncThunk(
  "getPOApprovedStatus",
  async (drawing_id) => {
    const request = await api(`api/admin/ispoapproved/${drawing_id}`, {}, "get");
    return request;
  }
);

export const getBOMPOItemsForCommercialInvoice = createAsyncThunk(
  "getBOMPOItemsForCommercialInvoice",
  async (purchase_order_id) => {
    const request = await api(`api/admin/commercialinvoice/bompoitems/${purchase_order_id}`, {}, "get");
    return request;
  }
);

export const generateCommercialInvoiceForPO = createAsyncThunk(
  "generateCommercialInvoiceForPO",
  async (payload) => {
    const request = await api(`api/admin/commercialinvoice/create/${payload.po_id}`, payload, "post");
    return request;
  }
);

const drawingPurchaseOrderSlice = createSlice({
  name: "drawingPurchaseOrder",
  initialState: {
    loading: false,
    bomSuppliers: [],
    purchaseOrders: [],
    totalCount: 0,
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(copyPOData.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(copyPOData.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload.message;
      state.error = null;
    })
    .addCase(copyPOData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
    .addCase(listPurchaseOrders.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(listPurchaseOrders.fulfilled, (state, action) => {
      state.purchaseOrders = action?.payload?.rows;
      state.totalCount = action?.payload.count;
      state.loading = false;
      state.error = null;
    })
    .addCase(listPurchaseOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
    .addCase(createPurchaseOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload.message;
      state.error = null;
    })
    .addCase(createPurchaseOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
    .addCase(deleteBomPOItem.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(deleteBomPOItem.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload.message;
      state.error = null;
    })
    .addCase(deleteBomPOItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
    .addCase(updateBomPOItem.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(updateBomPOItem.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload.message;
      state.error = null;
    })
    .addCase(updateBomPOItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
    .addCase(addBomPOItem.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(addBomPOItem.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload.message;
      state.error = null;
    })
    .addCase(addBomPOItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
  },
});
export default drawingPurchaseOrderSlice.reducer;
