import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Link, useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import { getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import { 
  listCommercialInvoices,
  deleteCommercialInvoice 
} from "../../store/reducer/drawing/commercialInvoiceSlice";
import Notes from "../Notes/Notes";
import { toast } from "react-toastify";

function CommercialInvoiceHistory() {
  const { id, drawing_id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    loading,
    commercialInvoices = [],
    totalCount,
  } = useSelector((state) => state.commercialInvoices);

  const [opportunityData, setOpportunityData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);

  useEffect(() => {
    dispatch(getProposalFinalStatus(drawing_id)).then((result) => {
      if (result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    });
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });
    dispatch(listCommercialInvoices({ search, page, limit, drawing_id }));
  }, [dispatch, drawing_id, id, search, page, limit]);

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setPage(newPage);
    dispatch(listCommercialInvoices({ search, page: newPage, limit, drawing_id }));
  };

  const handleDeleteInvoice = (invoiceId) => {
    if (window.confirm("Are you sure you want to delete this commercial invoice?")) {
      dispatch(deleteCommercialInvoice(invoiceId))
        .then(() => {
          // Refresh the list after successful deletion
          dispatch(listCommercialInvoices({ search, page, limit, drawing_id }));
          toast.success("Commercial invoice deleted!")
        })
        .catch((error) => {
          console.error("Error deleting invoice:", error);
          alert("Failed to delete invoice. Please try again.");
        });
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Commercial Invoices
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Invoice History
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-commercialinvoice-history" drawing_id={drawing_id} />
      </div>

      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            Install
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            Create PO
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}>
          <div className="font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg text-black hover:bg-[#55A14A] hover:text-white">
            PO History
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Commercial Invoice
          </div>
        </Link>
      </div>

      {isProposalFinalised ? (
        <div className="flex flex-col mt-[20px] gap-[40px]">
          <div className="">
            <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
              <div className="flex flex-col">
                <div className="mt-5 overflow-x-auto sm:rounded-lg">
                  <table className="w-full border-collapse">
                    <thead className="bg-white">
                      <tr className="rounded-[12px]">
                        {["Id", "Purchase Order", "Truck", "Date", "View Invoice", "Action"].map((header) => (
                          <th
                            key={header}
                            scope="col"
                            className="px-6 py-3 text-left font-Inter text-[14px] font-semibold text-[#6D6D6F] leading-[20px] tracking-wider whitespace-nowrap"
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {!loading ? (
                        commercialInvoices.map((invoice) => (
                          <tr
                            key={invoice.id}
                            className="border-b border-gray-200 hover:bg-gray-50"
                          >
                            <td className="px-6 py-4 text-[14px] font-semibold text-[#6D6D6F] whitespace-nowrap">
                              {invoice.id}
                            </td>
                            <td className="px-6 py-4 text-[14px] text-[#6D6D6F] whitespace-nowrap">
                              {invoice.purchase_order_id || "N/A"}
                            </td>
                            <td className="px-6 py-4 text-[14px] text-[#6D6D6F] whitespace-nowrap">
                              {invoice.truck_name || "N/A"}
                            </td>
                            <td className="px-6 py-4 text-[14px] text-[#6D6D6F] whitespace-nowrap">
                              {new Date(invoice.createdAt).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </td>
                            <td className="px-6 py-4 text-[14px] font-medium whitespace-nowrap">
                              {invoice.invoice_file ? (
                                <a
                                  href={invoice.invoice_file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-dark-600 hover:underline"
                                >
                                  View
                                </a>
                              ) : (
                                <span className="text-gray-400">N/A</span>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <button
                                onClick={() => handleDeleteInvoice(invoice.id)}
                                className="text-red-600 hover:text-red-800"
                                title="Delete Invoice"
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6} className="py-8 text-center">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              size="2xl"
                              className="text-[#55A14A] animate-spin"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
            <div className="hidden md:block font-Inter font-[300] text-nowrap">
              <span>
                Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
                pages
              </span>
            </div>
            <div className="w-full flex justify-end">
              <ReactPaginate
                className="react_pagination_ul"
                breakLabel={
                  <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                    <FontAwesomeIcon icon={faEllipsis} size="xl" />
                  </span>
                }
                nextLabel={
                  <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                    <FontAwesomeIcon icon={faAngleRight} size="xl" />
                  </span>
                }
                onPageChange={handlePageClick}
                pageCount={Math.ceil(totalCount / limit)}
                previousLabel={
                  <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                    <FontAwesomeIcon icon={faAngleLeft} size="xl" />
                  </span>
                }
                activeClassName="bg-black text-white"
                pageClassName="block text-black hover:bg-black hover:text-white rounded-md mr-4"
                pageLinkClassName="w-10 h-10 flex justify-center items-center"
                containerClassName="flex items-center justify-center"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <span className="font-Inter font-[500] leading-[30px] text-[20px]">
            Please finalise proposal before viewing invoice history.
          </span>
        </div>
      )}
    </>
  );
}

export default CommercialInvoiceHistory;