import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getUserForUI } from "../../store/reducer/user/userSlice";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import {
  getDrawingById,
  updateDrawing,
  getDrawingLockStatus
} from "../../store/reducer/drawing/drawingSlice";
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import {  getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import Notes from "../Notes/Notes";

function EditDrawing() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { options = [] } = useSelector((state) => state.ddOptions);

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setDrawingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [proposalStatus, setProposalStatus] = useState(true);
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);
  const [isDrawingLocked, setIsDrawingLocked] = useState(false);

  useEffect(() => {
    dispatch(getDrawingLockStatus(drawing_id)).then(result => {
      if(result?.payload?.locked) {
        setIsDrawingLocked(result.payload.locked);
      }
    });
    dispatch(getProposalFinalStatus(drawing_id)).then(result => {
      if(result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    })
    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setDrawingData(result.payload);
        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
        setLoading(false);
      }
    });
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });
    dispatch(getUserForUI()).then((result) => {
      if (result.payload) {
        setUserData(result.payload);
      }
    });
    dispatch(getDdOptionForUI());
  }, []);

  const validationSchema = Yup.object().shape({
    requested_by: Yup.string().required("please select requested by"),
    date_required: Yup.string().required("please select date required"),
    status: Yup.string().required("please enter opportunity name"),
  });

  const initialValues = {
    requested_by: drawingData?.requested_by || "",
    drawing_revision: drawingData?.drawing_revision || "",
    date_requested: drawingData?.date_requested || "",
    date_required: drawingData?.date_required || "",
    status: drawingData?.status || "",
    is_outside_uk: drawingData?.is_outside_uk || false,
    storage_unit_required: drawingData?.storage_unit_required || "",
    mezzanine_required: drawingData?.mezzanine_required || "",
    system_height: drawingData?.system_height || "",
    door_height: drawingData?.door_height || "",
    door_colour: drawingData?.door_colour || "",
    jamb_colour: drawingData?.jamb_colour || "",
    roller_doors_or_double_swings:
      drawingData?.roller_doors_or_double_swings || "",
    mess_required_and_type: drawingData?.mess_required_and_type || "",
    soffit_required_and_type: drawingData?.soffit_required_and_type || "",
    corner_guard_type: drawingData?.corner_guard_type || "",
    kickers_required_and_type: drawingData?.kickers_required_and_type || "",
    lining_panels_required_and_type:
      drawingData?.lining_panels_required_and_type || "",
    lockers_required_and_type: drawingData?.lockers_required_and_type || "",
    lift_required: drawingData?.lift_required || "",
    survey_plan_overview: drawingData?.survey_plan_overview || false,
    survey_section_view: drawingData?.survey_section_view || false,
    photos_in_oneDrive: drawingData?.photos_in_oneDrive || false,
    client_autocad_design_attached:
      drawingData?.client_autocad_design_attached || false,
    notes: drawingData?.notes || "",
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;
    console.log(values);
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateDrawing({ data: formData, id: drawing_id })).then(
      (result) => {
        if (result.payload) {
          navigate(`/opportunity/${id}`);
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };
  return (
    <>
      <div className="block md:grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Drawing / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Drawing
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="edit-drawing" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Commercial Invoice
          </div>
        </Link>
      </div>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="block md:flex gap-[20px]">
                <div className="w-[100%] mb-[32px] md:mb-0 md:w-[40%]">
                  <div className="md:grid md:grid-cols-2 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 items-center">
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="requested_by"
                      >
                        Requested By
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="requested_by"
                          name="requested_by"
                          disabled
                          onChange={(e) => {
                            setFieldValue("requested_by", e.target.value);
                          }}
                        >
                          <option value={""}>Select Customer</option>
                          {userData.map((user) => (
                            <option value={user.id} key={user.id}>
                              {user.firstname} {user.lastname}
                            </option>
                          ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.requested_by && touched.requested_by ? (
                        <div className="text-red-700">
                          {errors.requested_by}
                        </div>
                      ) : null}
                    </div>

                    {/* <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="job_no"
                    >
                      Enq/Job No
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="job_no"
                      name="job_no"
                      onChange={(e) => {
                        setFieldValue("job_no", e.target.value);
                      }}
                    />
                  </div> */}

                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="drawing_revision"
                      >
                        Drawing Revisions
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        id="drawing_revision"
                        name="drawing_revision"
                        onChange={(e) => {
                          setFieldValue("drawing_revision", e.target.value);
                        }}
                      />
                    </div>

                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="date_requested"
                      >
                        Date Required
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="date_requested"
                        name="date_requested"
                        onChange={(e) => {
                          setFieldValue("date_requested", e.target.value);
                        }}
                      />
                      {errors.date_requested && touched.date_requested ? (
                        <div className="text-red-700">
                          {errors.date_requested}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="status"
                      >
                        Status
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="status"
                          name="status"
                          onChange={(e) => {
                            setFieldValue("status", e.target.value);
                          }}
                        >
                          <option value={""}>Select Status</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Drawing" &&
                                option.field_name === "Status"
                            )
                            .map((option) => (
                              <option
                                key={option.id}
                                value={option.id}
                                selected={option.is_default ? true : false}
                              >
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {errors.status && touched.status ? (
                        <div className="text-red-700">{errors.status}</div>
                      ) : null}
                    </div>

                    <div className="mb-2">
                      <div className="flex items-center mb-4">
                        <Field
                          id="is_outside_uk"
                          type="checkbox"
                          checked={values.is_outside_uk}
                          onChange={(e) =>
                            setFieldValue("is_outside_uk", e.target.checked)
                          }
                          className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="is_outside_uk"
                          className="ms-2 text-sm font-medium text-[#9E9E9E]"
                        >
                          Location outside UK
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[100%] md:w-[60%]">
                  <div className="flex gap-[30px] bg-[#F8F8F8] rounded-lg p-2 mb-4 justify-around w-full overflow-scroll text-nowrap">
                    <span
                      className={`font-Inter text-[14px] font-[400] px-5 py-2 rounded-lg cursor-pointer text-center inline-flex items-center ${
                        activeTab === 1
                          ? "text-[#55A14A] border border-[#55A14A]"
                          : "text-black hover:border hover:border-[#55A14A] hover:text-[#55A14A]"
                      }`}
                      onClick={() => handleTabClick(1)}
                    >
                      Design to include
                    </span>
                    <span
                      className={`font-Inter text-[14px] font-[400] px-5 py-2 rounded-lg cursor-pointer text-center inline-flex items-center ${
                        activeTab === 2
                          ? "text-[#55A14A] border border-[#55A14A]"
                          : "text-black hover:border hover:border-[#55A14A] hover:text-[#55A14A]"
                      }`}
                      onClick={() => handleTabClick(2)}
                    >
                      Partition Details
                    </span>
                    <span
                      className={`font-Inter text-[14px] font-[400] px-5 py-2 rounded-lg cursor-pointer text-center inline-flex items-center ${
                        activeTab === 3
                          ? "text-[#55A14A] border border-[#55A14A]"
                          : "text-black hover:border hover:border-[#55A14A] hover:text-[#55A14A]"
                      }`}
                      onClick={() => handleTabClick(3)}
                    >
                      Checklist
                    </span>
                  </div>
                  <div className={`${activeTab === 1 ? "" : "hidden"}`}>
                    <div className="md:grid md:grid-cols-1 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6">
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="storage_unit_required"
                        >
                          Storage Units Required
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="storage_unit_required"
                          name="storage_unit_required"
                          onChange={(e) => {
                            setFieldValue(
                              "storage_unit_required",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="mezzanine_required"
                        >
                          Mezzanine Required
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="mezzanine_required"
                          name="mezzanine_required"
                          onChange={(e) => {
                            setFieldValue("mezzanine_required", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`${activeTab === 2 ? "" : "hidden"}`}>
                    <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6">
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="system_height"
                        >
                          System Height
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="system_height"
                          name="system_height"
                          onChange={(e) => {
                            setFieldValue("system_height", e.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="door_height"
                        >
                          Door Height
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="door_height"
                          name="door_height"
                          onChange={(e) => {
                            setFieldValue("door_height", e.target.value);
                          }}
                        />
                      </div>

                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="door_colour"
                        >
                          Door Colour
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="door_colour"
                          name="door_colour"
                          onChange={(e) => {
                            setFieldValue("door_colour", e.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="jamb_colour"
                        >
                          Jamb Colour
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="jamb_colour"
                          name="jamb_colour"
                          onChange={(e) => {
                            setFieldValue("jamb_colour", e.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="roller_doors_or_double_swings"
                        >
                          Roller Doors or Double Swings
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="roller_doors_or_double_swings"
                          name="roller_doors_or_double_swings"
                          onChange={(e) => {
                            setFieldValue(
                              "roller_doors_or_double_swings",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="mess_required_and_type"
                        >
                          Mesh Required & Type
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="mess_required_and_type"
                          name="mess_required_and_type"
                          onChange={(e) => {
                            setFieldValue(
                              "mess_required_and_type",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="soffit_required_and_type"
                        >
                          Soffit Required & Type
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="soffit_required_and_type"
                          name="soffit_required_and_type"
                          onChange={(e) => {
                            setFieldValue(
                              "soffit_required_and_type",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="corner_guard_type"
                        >
                          Corner Guard Type
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="corner_guard_type"
                          name="corner_guard_type"
                          onChange={(e) => {
                            setFieldValue("corner_guard_type", e.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="kickers_required_and_type"
                        >
                          Kickers Required & Type
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="kickers_required_and_type"
                          name="kickers_required_and_type"
                          onChange={(e) => {
                            setFieldValue(
                              "kickers_required_and_type",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="lining_panels_required_and_type"
                        >
                          Lining Panels Required & Type
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="lining_panels_required_and_type"
                          name="lining_panels_required_and_type"
                          onChange={(e) => {
                            setFieldValue(
                              "lining_panels_required_and_type",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="lockers_required_and_type"
                        >
                          Lockers Required & Type
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="lockers_required_and_type"
                          name="lockers_required_and_type"
                          onChange={(e) => {
                            setFieldValue(
                              "lockers_required_and_type",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="lift_required"
                        >
                          Lift Required
                        </label>
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="lift_required"
                          name="lift_required"
                          onChange={(e) => {
                            setFieldValue("lift_required", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      activeTab === 3 ? "" : "hidden"
                    } bg-[#F8F8F8] rounded-[12px] p-6`}
                  >
                    <div className={`md:grid md:grid-cols-2 gap-[20px]`}>
                      <div className="mb-2">
                        <div className="flex items-center mb-4">
                          <Field
                            id="survey_plan_overview"
                            type="checkbox"
                            checked={values.survey_plan_overview}
                            onChange={(e) =>
                              setFieldValue(
                                "survey_plan_overview",
                                e.target.checked
                              )
                            }
                            className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="survey_plan_overview"
                            className="ms-2 text-sm font-medium text-[#9E9E9E]"
                          >
                            Survey Plan Overview
                          </label>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="flex items-center mb-4">
                          <Field
                            id="survey_section_view"
                            type="checkbox"
                            checked={values.survey_section_view}
                            onChange={(e) =>
                              setFieldValue(
                                "survey_section_view",
                                e.target.checked
                              )
                            }
                            className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="survey_section_view"
                            className="ms-2 text-sm font-medium text-[#9E9E9E]"
                          >
                            Survey Section View
                          </label>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="flex items-center mb-4">
                          <Field
                            id="photos_in_oneDrive"
                            type="checkbox"
                            checked={values.photos_in_oneDrive}
                            onChange={(e) =>
                              setFieldValue(
                                "photos_in_oneDrive",
                                e.target.checked
                              )
                            }
                            className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="photos_in_oneDrive"
                            className="ms-2 text-sm font-medium text-[#9E9E9E]"
                          >
                            Photos in OneDrive
                          </label>
                        </div>
                      </div>

                      <div className="mb-2">
                        <div className="flex items-center">
                          <Field
                            id="client_autocad_design_attached"
                            type="checkbox"
                            checked={values.client_autocad_design_attached}
                            onChange={(e) =>
                              setFieldValue(
                                "client_autocad_design_attached",
                                e.target.checked
                              )
                            }
                            className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="client_autocad_design_attached"
                            className="ms-2 text-sm font-medium text-[#9E9E9E]"
                          >
                            Client provided DWG / PDF attached
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1">
                      <div className="mb-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="notes"
                        >
                          Notes
                        </label>
                        <Field
                          as="textarea"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          id="notes"
                          name="notes"
                          onChange={(e) => {
                            setFieldValue("notes", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isProposalFinalised && !isDrawingLocked && (
                <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                  <div>
                    <button
                      type="submit"
                      name="submit"
                      className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                        isSubmitting || proposalStatus
                          ? "bg-gray-300"
                          : "bg-[#1C1C20]"
                      }`}
                      disabled={isSubmitting || proposalStatus}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditDrawing;
