import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listDrawings = createAsyncThunk(
  "listDrawings",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/drawings?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const searchDrawings = createAsyncThunk(
  "searchDrawings",
  async ({ customer_id, drawing_status, date_required }) => {
    const request = await api(
      `api/admin/searchdrawings?customer_id=${customer_id}&drawing_status=${drawing_status}&date_required=${date_required}`,
      "",
      "get"
    );
    return request;
  }
);

export const listDrawingsByOpportunity = createAsyncThunk(
  "listDrawingsByOpportunity",
  async ({ opportunity_id, search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/drawingbyopportunity/${opportunity_id}?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getDrawingById = createAsyncThunk(
  "getDrawingById",
  async (data) => {
    const request = await api(`api/admin/drawing/${data}`, "", "get");
    return request;
  }
);

export const getDrawingByOpportunityForUI = createAsyncThunk(
  "getDrawingByOpportunityForUI",
  async (data) => {
    const request = await api(`api/admin/drawingbyopportunityforui/${data}`, "", "get");
    return request;
  }
);

export const getMaterialListByDrawing = createAsyncThunk(
  "getMaterialListByDrawing",
  async (data) => {
    const request = await api(`api/admin/materiallistbydrawing/${data}`, "", "get");
    return request;
  }
);


export const addDrawing = createAsyncThunk("addDrawing", async (data) => {
  const request = await api("api/admin/drawing", data, "post");
  return request;
});

export const updateDrawing = createAsyncThunk(
  "updateDrawing",
  async ({ data, id }) => {
    const request = await api(`api/admin/drawing/${id}`, data, "post");
    return request;
  }
);

export const copyDrawing = createAsyncThunk(
  "copydrawing",
  async (drawing_id) => {
    const request = await api(`api/admin/copydrawing/${drawing_id}`, "", "get");
    return request;
  }
);

export const toggleDrawinglock = createAsyncThunk(
  "toggledrawinglock",
  async (drawing_id) => {
    const request = await api(`api/admin/toggledrawinglock/${drawing_id}`, "", "get");
    return request;
  }
);

export const getDrawingLockStatus = createAsyncThunk(
  "getdrawinglockstatus",
  async (drawing_id) => {
    const request = await api(`api/admin/getdrawinglockstatus/${drawing_id}`, "", "get");
    return request;
  }
);

const drawingSlice = createSlice({
  name: "drawing",
  initialState: {
    loading: false,
    drawings: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listDrawingsByOpportunity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listDrawingsByOpportunity.fulfilled, (state, action) => {
        state.drawings = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listDrawingsByOpportunity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(listDrawings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listDrawings.fulfilled, (state, action) => {
        state.drawings = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listDrawings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchDrawings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchDrawings.fulfilled, (state, action) => {
        state.drawings = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(searchDrawings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addDrawing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addDrawing.fulfilled, (state, action) => {
        state.loading = false;
        state.drawings = action.payload;
        state.error = null;
      })
      .addCase(addDrawing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDrawing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDrawing.fulfilled, (state, action) => {
        state.loading = false;
        state.drawings = state.drawings.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateDrawing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default drawingSlice.reducer;
