import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";
import { getBomByDrawingId } from "../../store/reducer/drawing/drawingBomSlice";
import { getSiteByDrawingId } from "../../store/reducer/drawing/drawingSiteSlice";
import PurchaseOrdersBOM from "./PurchaseOrdersBOM";
import PurchaseOrderCart from "./PurchaseOrderCart";
import PurchaseOrdersInstall from "./PurchaseOrdersInstall";

import {
  getAdditionalOptionSuppliers,
  getBomSuppliers,
  getInstallSuppliers,
} from "../../store/reducer/drawing/drawingPurchaseOrderSlice";
import {  getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import PurchaseOrdersAdditionalOption from "./PurchaseOrdersAdditionalOption";
import Notes from "../Notes/Notes";

function PurchaseOrders() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setDrawingData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bomData, setBomData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(1);
  const [areaField, setAreaField] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [itemGroup, setItemGroup] = useState(null);
  const [supplierData, setSupplierData] = useState(null);
  const [purchaseOrderObject, setPurchaseOrderObject] = useState({
    bom: {},
    installs: {},
    additionalOptions: {},
    assignedItems: { bom: [], installs: [], additionalOptions: [] },
  });
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);

  const { options = [] } = useSelector((state) => state.ddOptions);

  useEffect(() => {
    dispatch(getDdOptionForUI({ ui_name: "Items", field_name: "Group" }));
  }, []);

  useEffect(() => {
    dispatch(getProposalFinalStatus(drawing_id)).then(result => {
      if(result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    })
    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setDrawingData(result.payload);
      }
    });
    dispatch(getSiteByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setSiteData(result.payload);
      }
    });
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });

    dispatch(getDdOptionForUI());
    dispatch(getBomByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setBomData(result.payload);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });
    dispatch(getBomByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setBomData(result.payload);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    setSupplier(null);
    setSupplierData(null);
    setItemGroup(null);
    setAreaField(null);
    if (activeCategory) {
      let getSupplierFunc = null;

      if (activeCategory === 1) {
        getSupplierFunc = getBomSuppliers;
      } else if (activeCategory === 2) {
        getSupplierFunc = getInstallSuppliers;
      } else if (activeCategory === 3) {
        getSupplierFunc = getAdditionalOptionSuppliers;
      }

      if (getSupplierFunc) {
        dispatch(getSupplierFunc(drawing_id))
          .then((result) => {
            if (result.payload) {
              setSupplierData(result.payload);
            } else {
              setSupplierData(null);
            }
          })
          .catch((error) => {
            setSupplierData(null);
          });
      } else {
        setSupplierData(null);
      }
    }
  }, [activeCategory, dispatch]);

  const initialValues = {
    selectField: "",
  };

  const handleTabClick = (index) => {
    setActiveCategory(index);
  };

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <>
      <div className="grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Purchase Orders / Create
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Purchase Orders
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-purchaseorder" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Commercial Invoice
          </div>
        </Link>
      </div>
      {isProposalFinalised ? (!loading ? (
        <>
          <div className="border border-gray-200 p-4 rounded-lg overflow-auto">
            <div className="flex gap-[20px] justify-between mb-2">
              <div className="col-span-4">
                <div className="flex gap-[30px] p-2 text-nowrap overflow-x-scroll">
                  <span
                    className={`flex justify-center border-b-4 py-2 px-5 cursor-pointer ${
                      activeCategory === 1
                        ? "text-[#55A14A] border-b-4 border-[#55A14A]"
                        : "text-black border-transparent hover:border-b-4 hover:border-[#55A14A] hover:text-[#55A14A]"
                    }`}
                    key={1}
                    onClick={() =>
                      handleTabClick(1)
                    }
                    data_id={1}
                  >
                    BOM
                  </span>
                  <span
                    className={`flex justify-center border-b-4 py-2 px-5 cursor-pointer ${
                      activeCategory === 2
                        ? "text-[#55A14A] border-b-4 border-[#55A14A]"
                        : "text-black border-transparent hover:border-b-4 hover:border-[#55A14A] hover:text-[#55A14A]"
                    }`}
                    key={2}
                    onClick={() =>
                      handleTabClick(2)
                    }
                    data_id={2}
                  >
                    Installs
                  </span>
                  <span
                    className={`flex justify-center border-b-4 py-2 px-5 cursor-pointer ${
                      activeCategory === 3
                        ? "text-[#55A14A] border-b-4 border-[#55A14A]"
                        : "text-black border-transparent hover:border-b-4 hover:border-[#55A14A] hover:text-[#55A14A]"
                    }`}
                    key={3}
                    onClick={() =>
                      handleTabClick(3)
                    }
                    data_id={3}
                  >
                    Additional Options
                  </span>
                </div>
              </div>
            </div>

            <div className="w-full bg-[#F8F8F8] p-2 mb-2">
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({
                  isSubmitting,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleSubmit,
                }) => (
                  <Form>
                    <div className="flex mb-[10px]">
                      <div className="w-full md:w-1/4 m-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="areaField"
                        >
                          Supplier
                        </label>
                        <div className="relative">
                          <Field
                            as="select"
                            id="supplierField"
                            name="supplierField"
                            className={`block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                              !activeCategory || !areaField ? "bg-gray-100" : ""
                            }`}
                            disabled={!activeCategory}
                            value={supplier?.supplier_id}
                            onChange={(e) => {
                              setFieldValue("supplierField", e.target.value);
                              setSupplier({
                                supplier_id: e.target.value,
                                supplier_name:
                                  e.target.options[e.target.selectedIndex].text,
                              });
                            }}
                          >
                            <option value={""}>Select Supplier</option>
                            {supplierData
                              ?.reduce((acc, current) => {
                                if (
                                  !acc.find(
                                    (item) =>
                                      item.supplier_id === current.supplier_id
                                  )
                                ) {
                                  acc.push(current);
                                }
                                return acc;
                              }, [])
                              ?.map((data) => (
                                <option
                                  value={data.supplier_id}
                                  key={data.supplier_id}
                                >
                                  {data.supplier_name}
                                </option>
                              ))}
                          </Field>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/4 m-2">
                        <label
                          className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                          htmlFor="areaField"
                        >
                          Area
                        </label>
                        <div className="relative">
                          <Field
                            as="select"
                            className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="areaField"
                            name="areaField"
                            disabled={!activeCategory || activeCategory == 3}
                            onChange={(e) => {
                              setFieldValue("areaField", e.target.value);
                              setAreaField(e.target.value);
                            }}
                          >
                            <option value="" label="Select Area" />
                            {siteData?.site_areas?.length
                              ? siteData.site_areas.map((area) => (
                                  <option value={area.id} key={area.id}>
                                    {area.area_name}
                                  </option>
                                ))
                            : ""}
                          </Field>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          {activeCategory && supplier && supplierData ? (
            <div className="mt-2">
              <span className="font-Inter leading-[30px] text-[18px]">
                Select Items
              </span>
              {(() => {
                switch (activeCategory) {
                  case 1:
                    return (
                      <PurchaseOrdersBOM
                        areaField={areaField}
                        drawing_id={drawing_id}
                        purchaseOrderObject={purchaseOrderObject}
                        setPurchaseOrderObject={setPurchaseOrderObject}
                        supplier={supplier}
                        supplierData={supplierData}
                        siteData={siteData}
                      />
                    );
                  case 2:
                    return (
                      <PurchaseOrdersInstall
                        areaField={areaField}
                        drawing_id={drawing_id}
                        purchaseOrderObject={purchaseOrderObject}
                        setPurchaseOrderObject={setPurchaseOrderObject}
                        supplier={supplier}
                        supplierData={supplierData}
                      />
                    );
                  case 3:
                    return (
                      <PurchaseOrdersAdditionalOption
                        areaField={areaField}
                        drawing_id={drawing_id}
                        purchaseOrderObject={purchaseOrderObject}
                        setPurchaseOrderObject={setPurchaseOrderObject}
                        supplier={supplier}
                        supplierData={supplierData}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          ) : null}
          {activeCategory ? (
            <PurchaseOrderCart
              data={{
                ...purchaseOrderObject,
                drawing_id: drawingData?.id,
                area_id: areaField,
                opportunity_id: opportunityData?.id
              }}
              setPurchaseOrderObject={setPurchaseOrderObject}
              activeCategory={activeCategory}
            />
          ) : null}
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )) : (
        <div className="text-center">
          <span className="font-Inter font-[500] leading-[30px] text-[20px]">
              Please finalise proposal before creating PO.
            </span>
        </div>
      )}
    </>
  );
}

export default PurchaseOrders;
