import React, {
  useEffect,
  useState,
  Suspense,
} from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  getAdditionalOptionItems
} from "../../store/reducer/drawing/drawingPurchaseOrderSlice";

// Lazy load the child component (BomItemsForPO)
const SupplierItems = React.lazy(() => import("./AdditionalOptionItemsForPO"));

function PurchaseOrdersAdditionalOption({ areaField, supplier, drawing_id, purchaseOrderObject, setPurchaseOrderObject }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [additionalOptionItems, setAdditionalOptionItems] = useState([]);

  const refreshAddOptData = () => {
    if (!supplier?.supplier_id) return;
    setLoading(true);
    dispatch(getAdditionalOptionItems({ drawing_id })).then(
      (result) => {
        if (result.payload) {
          setAdditionalOptionItems(result.payload);          
        }
        setLoading(false);
      }
    );
  };  

  useEffect(() => {
    refreshAddOptData()
  }, [supplier?.supplier_id, drawing_id, areaField])

  const handleItemAssign = (item, supplier, assign) => {
    setPurchaseOrderObject((prevState) => {
      const updatedadditionalOptions = { ...prevState.additionalOptions };
      let updatedadditionalOptionAssignedItems = [...(prevState.assignedItems?.additionalOptions || [])];
  
      if (assign) {
        if (!updatedadditionalOptions[supplier?.supplier_id]) {
          updatedadditionalOptions[supplier?.supplier_id] = { supplier, items: [item] };
        } else if (
          !updatedadditionalOptions[supplier?.supplier_id].items.some(
            (it) => it.id === item.id
          )
        ) {
          updatedadditionalOptions[supplier?.supplier_id].items.push(item);
        }
        if (!updatedadditionalOptionAssignedItems.some((it) => it.item.id === item.id)) {
          updatedadditionalOptionAssignedItems.push({ item, supplier });
        }
      } else {
        if (updatedadditionalOptions[supplier?.supplier_id]) {
          updatedadditionalOptions[supplier?.supplier_id].items = updatedadditionalOptions[supplier?.supplier_id].items.filter(
            (it) => it.id !== item.id
          );
  
          // If no items left for this supplier, remove the supplier entry
          if (updatedadditionalOptions[supplier?.supplier_id].items.length === 0) {
            delete updatedadditionalOptions[supplier?.supplier_id];
          }
        }
  
        updatedadditionalOptionAssignedItems = updatedadditionalOptionAssignedItems.filter(
          (it) => it.item.id !== item.id
        );
      }
  
      return {
        ...prevState,
        additionalOptions: updatedadditionalOptions,
        assignedItems: { ...prevState.assignedItems, additionalOptions: updatedadditionalOptionAssignedItems.length > 0 ? updatedadditionalOptionAssignedItems : undefined },
      };
    });
  };
  
  
  return (
    <>
      {!loading ? (
        <div className="flex gap-[20px] mb-[10px]">
          <div className="bg-[#F8F8F8] w-full rounded-[12px] p-4 items-center">
            {supplier?.supplier_id ? (
              <Suspense fallback={<div>Loading Supplier Items...</div>}>
                <SupplierItems
                  handleItemAssign={handleItemAssign}
                  supplier={supplier}
                  purchaseOrderObject={purchaseOrderObject}
                  additionalOptionItems={additionalOptionItems}
                  refreshAddOptData={refreshAddOptData}
                  drawing_id={drawing_id}
                  areaField={areaField}
                />
              </Suspense>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
        </div>
      )}
    </>
  );
}

export default PurchaseOrdersAdditionalOption;
