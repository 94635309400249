import React, { useEffect, useState } from "react";
import { Formik, Form, Field, getIn } from "formik";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";

import { getDrawingById, getDrawingLockStatus } from "../../store/reducer/drawing/drawingSlice";
import {
  getDiscountByDrawingId,
  updateDrawingDiscount,
} from "../../store/reducer/drawing/drawingDiscountSlice";
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import {  getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import Notes from "../Notes/Notes";

function EditDrawingDiscount() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setdrawingData] = useState([]);
  const [discountData, setDiscountData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [proposalStatus, setProposalStatus] = useState(true);
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);
  const [isDrawingLocked, setIsDrawingLocked] = useState(false);

  useEffect(() => {
    dispatch(getDrawingLockStatus(drawing_id)).then(result => {
      if(result?.payload?.locked) {
        setIsDrawingLocked(result.payload.locked);
      }
    });
    dispatch(getProposalFinalStatus(drawing_id)).then(result => {
      if(result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    })
    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setdrawingData(result.payload);
        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
      }
    });
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });
    dispatch(getDiscountByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setDiscountData(result.payload);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  const validationSchema = Yup.object().shape({
    discount: Yup.number().required("please enter discount"),
    requested_on: Yup.string().required("please select date"),
  });

  const initialValues = {
    discount: discountData?.discount || 0,
    requested_on:
      discountData?.requested_on || new Date().toISOString().split("T")[0],
    reason: discountData?.reason || "",
    is_approved: discountData?.is_approved || false,
    approved_by: discountData?.approved_by_name || "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    values.drawing_id = drawing_id;
    values.drawing_uniqueId = drawingData.uniqueId;
    values.requested_by = JSON.parse(localStorage.getItem("userSession")).id;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;
    if (values.is_approved === true) {
      values.approved_by = JSON.parse(localStorage.getItem("userSession")).id;
    } else {
      values.approved_by = "";
    }

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(
      updateDrawingDiscount({ data: formData, id: drawing_id })
    ).then((result) => {
      console.log(result);
      if (result.payload) {
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Drawing Discount / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Discount
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-discount" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}/commercial-invoice`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Commercial Invoice
          </div>
        </Link>
      </div>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="flex gap-[20px] mb-[30px]">
                <div className="w-[60%] bg-[#F8F8F8] rounded-[12px] p-6 items-center">
                  <div className="md:grid md:grid-cols-2 gap-[20px] mb-2">
                    <div className="mb-2 ">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="discount"
                      >
                        How Much Discount (%)
                      </label>
                      <div className="relative">
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="discount"
                          name="discount"
                          onChange={(e) => {
                            setFieldValue("discount", e.target.value);
                          }}
                          disabled={values.is_approved}
                        ></Field>
                      </div>
                      {errors.discount && touched.discount ? (
                        <div className="text-red-700">{errors.discount}</div>
                      ) : null}
                    </div>
                    <div className="mb-2 ">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="requested_on"
                      >
                        Date Requested
                      </label>
                      <div className="relative">
                        <Field
                          type="date"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="requested_on"
                          name="requested_on"
                          onChange={(e) => {
                            setFieldValue("requested_on", e.target.value);
                          }}
                          disabled
                        ></Field>
                      </div>
                      {errors.requested_on && touched.requested_on ? (
                        <div className="text-red-700">
                          {errors.requested_on}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-2 col-span-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="reason"
                      >
                        Reason
                      </label>
                      <div className="relative">
                        <Field
                          as="textarea"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="reason"
                          name="reason"
                          onChange={(e) => {
                            setFieldValue("reason", e.target.value);
                          }}
                          disabled={values.is_approved}
                        ></Field>
                      </div>
                      {errors.reason && touched.reason ? (
                        <div className="text-red-700">{errors.reason}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="w-[40%] bg-[#F8F8F8] rounded-[12px] p-6 items-center">
                  <div className="md:grid md:grid-cols-1 gap-[20px] mb-2">
                    <div className="mb-2 mt-6">
                      <div className="flex items-center mb-4">
                        <Field
                          id="is_approved"
                          type="checkbox"
                          checked={values.is_approved}
                          onChange={(e) =>
                            setFieldValue("is_approved", e.target.checked)
                          }
                          className="w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="is_approved"
                          className="ms-2 text-md font-medium text-[#9E9E9E]"
                        >
                          Approve Discount
                        </label>
                      </div>
                    </div>
                    <div className="mb-2 ">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="approved_by"
                      >
                        Approved By
                      </label>
                      <div className="relative">
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="approved_by"
                          name="approved_by"
                          disabled
                        ></Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!isProposalFinalised && !isDrawingLocked && (
                <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                  <div>
                    <button
                      type="submit"
                      name="submit"
                      className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                        isSubmitting || proposalStatus
                          ? "bg-gray-300"
                          : "bg-[#1C1C20]"
                      }`}
                      disabled={isSubmitting || proposalStatus}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditDrawingDiscount;
